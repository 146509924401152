import React from "react";
import "./Insurance_Journey.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css";

function Insurance_Journey() {
  // Define the journey steps data
  const journeySteps = [
    {
      id: "Step 1",
      title: "Contact Us",
      description:
        "Start by reaching out to us. Let us know what type of insurance you are looking to buy—motor, health, home, or travel.",
    },
    {
      id: "Step 2",
      title: "Talk to Our Insurance Experts",
      description:
        "Share your specific needs with our insurance experts. This information helps them provide you with the most suitable options for your coverage.",
    },
    {
      id: "Step 3",
      title: "Get a Personalized Quote",
      description:
        "Receive a personalized quote based on your final requirements from our insurance experts. This ensures you get the best deal for your needs.",
    },
    {
      id: "Step 4",
      title: "Review and Choose",
      description:
        "Look closely at the policy details and choose the one that works best for you.",
    },
    {
      id: "Step 5",
      title: "Get Cashbacks and Discounts",
      description:
        "Take advantage of any cashback and discounts available. Make the payment and enjoy your coverage!",
    },
  ];

  return (
    <>
      <section className="Insurance_Journey_holder">
        <div className="container">
          <div className="Insurance_Journey">
            <div className="section-heading-holder">
              <h3>Essential Steps</h3>
              <p> for Choosing the Right Insurance</p>
            </div>

            <div className="slider-section">
              <Swiper
                spaceBetween={50}
                slidesPerView={4}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                className="Insurance_Journey-slider"
                modules={[Navigation, Autoplay]}
                // loop={true}
                // autoplay={{
                //   delay: 2500,
                //   disableOnInteraction: false,
                // }}
                navigation={true}
                breakpoints={{
                  // when window width is >= 320px
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  480: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  1299: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
              >
                {journeySteps.map((step) => (
                  <SwiperSlide key={step.id}>
                    <div className="Insurance_Journey_card_holder">
                      <div className="Insurance_Journey_card">
                        <div className="content-holder">
                          <h4>{step.id}</h4>
                          <h6>{step.title}</h6>
                          <p>{step.description}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Insurance_Journey;
