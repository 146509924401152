import React from "react";
import Our_Top_Plan_card from "../../../Common_component/Our_Top_Plan_card/Our_Top_Plan_card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "swiper/css/pagination";
import "swiper/css";

function Health_Insurance_Our_Top_Plan() {
  const planBenefits1 = [
    "Sum Insured: 5 Lakhs to 3 Crores",
    "100% Restore Benefit",
    "50% Cumulative Bonus",
    "Cashless Health Checkup",
    "Wellness Services",
    "Ambulance & Travel Benefits",
    "60 Days Pre & Post-Hospitalization Expenses",
    "Medical Devices Cover",
    "Room Type: Single Private Room, Flexible Family Plan",
    "Free Medical Health Checkups Every Year"
  ];
  

  const planBenefits2 = [
    "Global Cover for Planned Hospitalization",
    "Sum Insured: 5 Lakhs to 3 Crores",
    "100% Restore Benefit",
    "Accidental Death Benefit",
    "Wellness Programs",
    "60 Days Pre & Post Hospitalization Expenses",
    "Medical Devices Cover",
    "All Room Types Covered",
    "Cumulative Bonus: 50%",
    "Tax Benefits up to 50,000"
  ];
  

  const planBenefits3 = [
    "Advanced Cover for Pre-existing Diseases",
    "No Claim Bonus: 500%",
    "Medical Devices Cover",
    "100% Restore Benefit",
    "Wellness Programs",
    "Room Eligibility: Single Private Room",
    "Domiciliary Benefit",
    "90 Days Pre & Post Hospitalization Expenses",
    "Accidental Coverage from 1st Day",
    "Free Annual Medical Checkup"
  ];
  

  const planBenefits4 = [
    "Accidental Death Benefit: 100%",
    "Sum Insured: 5 Lakhs to 5 Crores",
    "Child Tuition Benefit",
    "Medical Devices Cover",
    "Loan Shield: 25% Sum Insured (Optional)",
    "Ambulance & Travel Benefits",
    "Emergency Care",
    "Hospital Daily Cash",
    "Coma Benefit",
    "Cost of Artificial Limbs"
  ];
  

  const planBenefits5 = [
    "Sum Insured: 5, 10 & 25 Lakhs",
    "Wellness Services",
    "30 Days Pre & 60 Days Post-Hospitalization Expenses",
    "Ayush Benefits",
    "Existing Illness Treatment After 30 Days (Except Accident)",
    "Annual Preventive Health Consultation",
    "Cumulative Bonus: 10%",
    "Single Private Room",
    "Home Care Treatment Cover",
    "Ambulance Cover"
  ];
  
  const planBenefits6 = [
    "Sum Insured: Up to 1 Crore",
    "Higher Cumulative Bonus",
    "Daycare Expenses for 540+ Diseases",
    "Global Cover (Optional)",
    "60 Days Pre & 90 Days Post-Hospitalization",
    "Ayush Benefits",
    "Wellness Services",
    "Domiciliary Treatment",
    "Family floater 3 Member Discount – 32%",
    "Tax deductions up to 75000 TC***"
  ];
  

  const planBenefits7 = [
    "Global Cover",
    "Infinite Sum Insured",
    "Infinite Claim Amount",
    "100% Infinite Cumulative Bonus",
    "Pre-existing Illness Treatment from 31st Day",
    "Infinite Reset (Unlimited Coverage)",
    "90 Days Pre & 180 Days Post Hospitalization Expenses",
    "Critical Illness Waiting Period: 90 Days",
    "Maternity Waiting Period: 90 Days",
    "Nursing at Home",
    "Personal Accident Cover"
  ];
  

  const planBenefits8 = [
    "Covers Self & Spouse",
    "Cover Hospitalization Costs for Daycare Treatments, Modern Treatments, & Physiotherapy Sessions",
    "100% Reset Possible if Sum Insured is Exhausted",
    "Coverage for Ayush Benefits",
    "Free Annual Health Checkup",
    "Domiciliary Hospitalization",
    "Ambulance Coverage",
    "30 Days Pre-Hospitalization Expenses",
    "Post-Hospitalization Expenses: 7% of Hospital Bill",
    "Cashless Treatment"
  ];
  

  return (
    <>
      <section className="Our_Top_Plan">
        <div className="container">
          <div className="heading-holder">
            <div className="Our_Top_Plan_heading_holder">
              <h3>Our Top Plan</h3>
            </div>
          </div>

          <div className="Our_Top_Plan_section">
            <Swiper
              spaceBetween={50}
              slidesPerView={4}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              className="Insurance_Journey-slider Our_Top_Plan_slider"
              modules={[Pagination]}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                480: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },

                575: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1299: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
            >
              <SwiperSlide>
                <div className="Our_Top_Plan_card_holder">
                  <Our_Top_Plan_card
                    cardColor={"#fff9eb"}
                    locationText={"Tata Aig Medicare Lite"}
                    companyText={
                      "Tata Aig Medicare Lite )(Individual/family) (entry age 91 days to 65 years)"
                    }
                    pricePremiumText={"$6535 /yr"}
                    fromPrice={"$10,000"}
                    toPrice={"$500,000"}
                    planBenefits={planBenefits1}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Our_Top_Plan_card_holder">
                  <Our_Top_Plan_card
                    cardColor={"#FFF1CE "}
                    locationText={"Tata AIG Medicare Premier"}
                    companyText={
                      "Tata AIG Medicare Premier: (Individual/Family) (5 years to 65 years)"
                    }
                    pricePremiumText={"$ 8333 /yr"}
                    fromPrice={"$100,000"}
                    toPrice={"$500,000"}
                    planBenefits={planBenefits2}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Our_Top_Plan_card_holder">
                  <Our_Top_Plan_card
                    cardColor={"#FCE8B4 "}
                    locationText={"Tata Aig supercharge"}
                    companyText={
                      "Tata Aig supercharge: (Individual/Family) Entry age (91 days to 65 years)"
                    }
                    pricePremiumText={" £6150 /yr"}
                    fromPrice={" £100,000"}
                    toPrice={" £250,000"}
                    planBenefits={planBenefits3}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Our_Top_Plan_card_holder">
                  <Our_Top_Plan_card
                    cardColor={"#FFE298"}
                    locationText={"Tata Aig Accidental Guard Plus"}
                    companyText={
                      "Tata Aig Accidental Guard Plus: (Individual/Family) (18 – 70) "
                    }
                    pricePremiumText={"€5400 /yr"}
                    fromPrice={"€30,000"}
                    toPrice={"€100,000"}
                    planBenefits={planBenefits4}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Our_Top_Plan_card_holder">
                  <Our_Top_Plan_card
                    cardColor={"#FFD774"}
                    locationText={"Tata AIG Eldercare"}
                    companyText={
                      "Tata AIG Eldercare (Elder people age above 61+ years)"
                    }
                    pricePremiumText={"$9500  /yr"}
                    fromPrice={"$50,000"}
                    toPrice={"$300,000"}
                    planBenefits={planBenefits5}
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Our_Top_Plan_card_holder">
                  <Our_Top_Plan_card
                    cardColor={"#FFD774"}
                    locationText={"TATA AIG Medicare Plus"}
                    companyText={"TATA AIG Medicare Plus (Top-up Plan) (18-65)"}
                    pricePremiumText={"₹9500  /yr"}
                    fromPrice={"₹3,920"}
                    toPrice={"₹8,658 annually"}
                    planBenefits={planBenefits6}
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Our_Top_Plan_card_holder">
                  <Our_Top_Plan_card
                    cardColor={"#FFD774"}
                    locationText={"ICICI LOMBARD"}
                    companyText={
                      "ICICI LOMBARD Elevate ( age from 3months to 50 years)"
                    }
                    pricePremiumText={"₹9500  /yr"}
                    fromPrice={" ₹3,920"}
                    toPrice={"₹8,658 annually"}
                    planBenefits={planBenefits7}
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Our_Top_Plan_card_holder">
                  <Our_Top_Plan_card
                    cardColor={"#FFD774"}
                    locationText={"ICICI Lombard "}
                    companyText={"Golden Shield (elderly people aged > 56)"}
                    pricePremiumText={"$14686  /yr"}
                    fromPrice={"$50,000"}
                    toPrice={"$300,000"}
                    noteText={
                      <>
                        <b>Note: </b> The premiums listed for a sum insured of ₹5 lakh are indicative and may vary based on age, existing medical conditions, and selected coverage options. Please confirm with an insurance representative for precise rates.
                      </>
                      
                    }
                    planBenefits={planBenefits8}
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default Health_Insurance_Our_Top_Plan;
