import React from "react";
import "./Proud_Partner.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay} from "swiper/modules";
import 'swiper/css/navigation';
import "swiper/css";

function Proud_Partner({data,IMG_URL}) {
  const proudPartnerData = [
    {
      title: "Proud-Partner-img",

      imgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Proud_Partner/Proud-Partner1.png",
    },
    {
      title: "Proud-Partner-img",

      imgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Proud_Partner/Proud-Partner2.png",
    },

    {
      title: "Proud-Partner-img",

      imgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Proud_Partner/Proud-Partner3.png",
    },

    {
      title: "Proud-Partner-img",

      imgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Proud_Partner/Proud-Partner4.png",
    },

    {
      title: "Proud-Partner-img",

      imgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Proud_Partner/Proud-Partner5.png",
    },

    {
      title: "Proud-Partner-img",

      imgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Proud_Partner/Proud-Partner6.png",
    },
  ];
  return (
    <>
      <section className="Proud_Partner_holder">
        <div className="container">
          <div className="Proud_Partner">
            <div className="row">
              <div className="col-lg-3 col-12 main-heading-holder">
                <div className="section-heading-holder">
                  <h3>Proud to Partner</h3>
                  <p>with Leading Brands</p>
                </div>
              </div>

              <div className="col-lg-9 col-12">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={5}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                  className="Proud_Partner-slider"
                  modules={[Navigation, Autoplay]}
                  navigation={true}
                // loop={true}
                // autoplay={{
                //   delay: 2500,
                //   disableOnInteraction: false,
                // }}
                  breakpoints={{
                    // when window width is >= 320px
                    320: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    // when window width is >= 480px
                    480: {
                      slidesPerView: 5,
                      spaceBetween: 20,
                    },
                    // when window width is >= 768px
                    768: {
                      slidesPerView: 5,
                      spaceBetween: 30,
                    },

                    // when window width is >= 992px
                    992: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                    // when window width is >= 1024px
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },

                    // when window width is >= 1200px
                    1299: {
                      slidesPerView: 5,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {data?.map((service, index) => (
                    <SwiperSlide>
                      <div className="Proud-Partner-img-holder">
                          <img
                            src={IMG_URL+service.image}
                            className="Proud-Partner-img"
                            alt={service.title}
                          />
                        
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Proud_Partner;
