import React from "react";
import "./Four_Wheeler_About_Us.css";

function Four_Wheeler_About_Us({ aboutHeading }) {
  return (
    <>
      <section className="Four_Wheeler_About_Us">
        <div className="container">
          <div className="Four_Wheeler_About_Us_holder">
            <div className="section-heading-holder">
              <h3>{aboutHeading}</h3>
            </div>

            <div className="content-holder">
              <div className="text-holder">
                <h3>Start Your Car Insurance Journey</h3>
                <p>
                  Car insurance, or four-wheeler insurance, is an agreement
                  between you and the insurance provider that protects your
                  vehicle against financial losses from unexpected events, such
                  as accidents and natural disasters. Many people view this
                  insurance as just a document to keep in their glove
                  compartment for show, but it offers significant financial
                  security. Accidents can happen to anyone, and repair bills can
                  be costly, making this coverage essential.
                </p>
              </div>

              <div className="text-holder">
                <h3>Types of Four-Wheeler Insurance</h3>
                <p>
                  There are different types of four-wheeler insurance available.
                  Third- party car insurance protects you from financial
                  liabilities if your car causes damage to someone else's
                  vehicle or property, or if it injures someone. This type of
                  insurance is mandatory in India under the Motor Vehicle Act if
                  you want to drive legally. On the other hand, comprehensive
                  car insurance covers both third-party liabilities and damages
                  to your own car, whether from accidents, theft, fire, or
                  natural disasters like storms and floods. It also covers
                  man-made damages, such as vandalism or riots. Additionally,
                  you can opt for a Personal Accident Cover, which provides
                  financial assistance in case of injuries from an accident.
                </p>
              </div>

              <div className="text-holder">
                <h3>Buying and Customizing Your Insurance</h3>
                <p>
                  When purchasing car insurance online, you can choose between
                  third-party coverage, comprehensive coverage, or a standalone
                  damage policy, depending on your needs. Tata AIG provides
                  various third-party and comprehensive car insurance policies
                  to help you find the right coverage for your vehicle. You can
                  also enhance your comprehensive policy with optional add-ons
                  for additional protection. Simply enter your car details and
                  get a personalized quote to select the ideal insurance policy
                  for your four-wheeler.
                </p>
              </div>

              <div className="text-holder">
                <h3>Importance of Renewal</h3>
                <p>
                  Timely renewal of your car insurance is crucial for
                  uninterrupted coverage. Third-party insurance rates are
                  subject to revision as per the IRDAI circular, which was
                  implemented on June 1, 2022. Stay informed and make sure your
                  policy is up to date to enjoy peace of mind while driving!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Four_Wheeler_About_Us;
