import React from "react";

function Privacy_Policy_Content() {
  return (
    <>
      <section className="Terms_Conditions_Content">
        <div className="container">
          <div className="Terms_Conditions_Content_holder">
            <ol>
              <li>
                <div className="text-holder">
                  <h3>Information We Collect </h3>

                  <p className="list-heading-text">
                    We may collect personal information directly from you, third
                    parties, or through automated technologies. This information
                    may include:
                  </p>

                  <p>
                    Personal Identification Information: Name, address, date of
                    birth, social security number, driver’s license number, etc.
                  </p>

                  <p>
                    Contact Information: Phone number, email address, mailing
                    address.
                  </p>

                  <p>
                    Insurance-Related Information: Policy numbers, claims
                    history, underwriting data, and payment information.
                  </p>

                  <p>
                    Health Information: When applicable for certain insurance
                    products (e.g., life or health insurance).
                  </p>

                  <p>
                    Financial Information: Bank account details, credit score,
                    payment history, and employment information.
                  </p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>How We Use Your Information </h3>
                  <p className="list-heading-text">
                    We use your personal data for the following purposes:
                  </p>

                  <p>To process insurance applications and claims.</p>

                  <p>
                    To verify your identity and assess your eligibility for
                    insurance products.
                  </p>

                  <p>To manage, administer, and improve our services.</p>

                  <p>
                    To communicate with you about your policies, updates, or
                    promotions.
                  </p>

                  <p>To comply with legal and regulatory obligations.</p>

                  <p>For fraud prevention and detection.</p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3> Sharing Your Information </h3>
                  <p className="list-heading-text">
                    We do not sell your data. However, we may share your
                    information with:
                  </p>
                  <p>
                    Service Providers: We may share data with third-party
                    vendors to assist in our business operations (e.g., IT
                    support, claims processing).
                  </p>
                  <p>
                    Affiliated Companies: To offer you additional insurance
                    products or services.
                  </p>
                  <p>
                    Regulatory and Legal Entities: When required by law, to
                    respond to subpoenas, legal processes, or government
                    requests.
                  </p>
                  <p>
                    Business Transfers: In the event of a merger, acquisition,
                    or sale of all or part of our business.
                  </p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3> Data Security </h3>

                  <p>
                    We implement industry-standard security measures to protect
                    your personal information from unauthorized access,
                    disclosure, alteration, or destruction. This includes using
                    encryption, secure servers, and regular security audits.
                    However, no system is completely secure, and we cannot
                    guarantee the absolute security of your data.
                  </p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3> Your Choices and Rights</h3>

                  <p className="list-heading-text">
                    Depending on your jurisdiction, you may have the following
                    rights regarding your personal information:
                  </p>
                  <p>Access: Request a copy of your data.</p>
                  <p>Correction: Request corrections to any inaccurate data.</p>
                  <p>
                    Deletion: Request deletion of your data, where applicable.
                  </p>

                  <p>Opt-Out: Opt out of receiving marketing communications.</p>

                  <p>
                    Data Portability: Request a copy of your data in a
                    structured format.
                  </p>

                  <p>
                    Restrict Processing: Request limitations on how we process
                    your data.
                  </p>

                  <p>
                    To exercise any of these rights, you can contact us at
                    [email address or phone number].
                  </p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3> Cookies and Tracking Technologies</h3>

                  <p>
                    We may use cookies and similar technologies to enhance your
                    experience on our website, analyze usage trends, and provide
                    personalized content. You can manage your cookie preferences
                    through your browser settings.
                  </p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Children’s Privacy </h3>
                  <p>
                    Our services are not intended for individuals under the age
                    of 18, and we do not knowingly collect personal information
                    from minors without verifiable parental consent.
                  </p>{" "}
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3> Changes to This Privacy Policy</h3>

                  <p>
                    We reserve the right to update this Privacy Policy at any
                    time. Any changes will be posted on this page with an
                    updated "Effective Date."
                  </p>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
}

export default Privacy_Policy_Content;
