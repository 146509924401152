import React, { useState, useEffect, useContext } from "react";
import Common_banner from "../../Common_component/Common_banner/Common_banner";
import Blog_Main_Content from "./Blog_Main_Content/Blog_Main_Content";
import { getAllProudtoPartner, getAllDataHappyCustomerData, getBlogs, getInnerBlogs } from "../../../utils/apis/common/Common";
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { Context } from "../../../utils/context";
function Blog_Main() {
  const [data, setData] = useState();
  const { IMG_URL } = useContext(Context);
  console.log(IMG_URL, "IMG_URL IMG_URL");

  const { id } = useParams();
  const getAllData = async () => {
    const res = await getBlogs();

    if (res?.success) {
      setData(res?.data?.list);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }
  useEffect(() => {
    getAllData();

  }, []);
  return (
    <>
      <Common_banner
        imgSrc={"/assets/image/Blog/Blog_Banner/Blog_banner-1.png"}
        mainHeading={"Explore"}
        heading={"Our Insurance Blog"}
      />
      {data?.length > 0 ? (
        <Blog_Main_Content data={data} IMG_URL={IMG_URL} />
      ) : (
        <p style={{ textAlign: 'center', fontWeight: 'bold', margin: '100px' }}>
          Blog Data not available
        </p>
      )}



    </>
  );
}

export default Blog_Main;
