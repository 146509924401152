import React from "react";
import Common_banner from "../Common_component/Common_banner/Common_banner";
import About_Us_Content from "./About_Us_Content/About_Us_Content";
import Vision_Mission_Value from "./Vision_Mission_Value/Vision_Mission_Value";
import Why_Choose_Us from "./Why_Choose_Us/Why_Choose_Us";
import Help_Us from "./Help_Us/Help_Us";
import About_Quick_Inquiry from "./About_Quick_Inquiry/About_Quick_Inquiry";

function About_Us() {
  return (
    <>
      <Common_banner
        imgSrc={
          "/assets/image/About_Us/About_Us_Banner/About-Us-Banner-img.png"
        }
        mainHeading={"About Us"}
        heading={"About Us"}
      />

      <About_Us_Content />

      <Vision_Mission_Value />

      <Why_Choose_Us />

      <Help_Us />

      <About_Quick_Inquiry />
    </>
  );
}

export default About_Us;
