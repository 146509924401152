import React from "react";
import "./Blog_Main_Content.css";
import Blog_card from "../../../Common_component/Blog_card/Blog_card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faSquare } from "@fortawesome/free-solid-svg-icons";

function Blog_Main_Content({data,IMG_URL}) {
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return an empty string if no date is provided
  
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",     // Day with leading zeros (01, 02, 12, etc.)
      month: "short",     // Abbreviated month (Jan, Feb, etc.)
      year: "numeric",    // Full year (2024)
    });
  };
  return (
    <>
      <section className="Blog_Main_Content">
        <div className="container">
          <div className="Blog_Main_Content_holder">
            <div className="row">
              <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12 order-md-1 order-2">
                <div className="row">
                  {data?.map((val)=>
                  <div className="col-md-6 col-sm-6">
                    <Blog_card data={val}IMG_URL={IMG_URL}/>
                  </div>)}

                  {/* <div className="col-md-6 col-sm-6">
                    <Blog_card />
                  </div>

                  <div className="col-md-6 col-sm-6">
                    <Blog_card />
                  </div>

                  <div className="col-md-6 col-sm-6">
                    <Blog_card />
                  </div> */}
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 order-md-2 order-1">
                <div className="blog_Category_side_holder">
                  {/* <div className="blog_Category_holder">
                    <div className="side_heading">
                      <div className="side_heading_holder">
                        <h3>Category</h3>
                      </div>
                    </div>

                    <div className="Category_list_holder">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="list_text">
                            <p>
                              <FontAwesomeIcon
                                className="square-icon"
                                icon={faSquare}
                              />
                              Health Insurance
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="list_text">
                            <p>
                              <FontAwesomeIcon
                                className="square-icon"
                                icon={faSquare}
                              />{" "}
                              Bike Insurance
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="list_text">
                            <p>
                              <FontAwesomeIcon
                                className="square-icon"
                                icon={faSquare}
                              />{" "}
                              Business Insurance
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="list_text">
                            <p>
                              <FontAwesomeIcon
                                className="square-icon"
                                icon={faSquare}
                              />{" "}
                              Auto Insurance
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="blog_Category_holder recent_Blog_holder">
                    <div className="side_heading">
                      <div className="side_heading_holder">
                        <h3>Recent Blogs</h3>
                      </div>
                    </div>

                    <div className="Category_list_holder recent_Blog">
                      <div className="row">
                      {data?.map((val)=>
                        <div className="col-md-12 card-after-border">
                          <div className="recent_Blog_card_holder ">
                            <div className="row">
                              <div className="col-lg-3 col-md-4 col-3">
                                <div className="img-holder">
                                  <img
                                    src={
                                      IMG_URL+
                                    val?.image
                                    }
                                    className="recent_Blog_card_img"
                                    alt="recent_Blog_card_img"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-9 col-md-8 col-9">
                                <div className="text-holder">
                                  <h3>
                                   {val?.title}
                                  </h3>
                                  <p>
                                    <FontAwesomeIcon
                                      className="calender-icon"
                                      icon={faCalendar}
                                    />
                                     {formatDate(val?.created_at)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        )}

                        {/* <div className="col-md-12 card-after-border">
                          <div className="recent_Blog_card_holder ">
                            <div className="row">
                              <div className="col-lg-3 col-md-4 col-3">
                                <div className="img-holder">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/image/Blog/Blog_main_card/Blog-card-img.png"
                                    }
                                    className="recent_Blog_card_img"
                                    alt="recent_Blog_card_img"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-9 col-md-8 col-9">
                                <div className="text-holder">
                                  <h3>
                                    Tips for Choosing the Right Auto Insurance
                                  </h3>
                                  <p>
                                    <FontAwesomeIcon
                                      className="calender-icon"
                                      icon={faCalendar}
                                    />
                                    12 Jan 2024
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 card-after-border">
                          <div className="recent_Blog_card_holder ">
                            <div className="row">
                              <div className="col-lg-3 col-md-4 col-3">
                                <div className="img-holder">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/image/Blog/Blog_main_card/Blog-card-img.png"
                                    }
                                    className="recent_Blog_card_img"
                                    alt="recent_Blog_card_img"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-9 col-md-8 col-9">
                                <div className="text-holder">
                                  <h3>
                                    Tips for Choosing the Right Auto Insurance
                                  </h3>
                                  <p>
                                    <FontAwesomeIcon
                                      className="calender-icon"
                                      icon={faCalendar}
                                    />
                                    12 Jan 2024
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 card-after-border">
                          <div className="recent_Blog_card_holder ">
                            <div className="row">
                              <div className="col-lg-3 col-md-4 col-3">
                                <div className="img-holder">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/image/Blog/Blog_main_card/Blog-card-img.png"
                                    }
                                    className="recent_Blog_card_img"
                                    alt="recent_Blog_card_img"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-9 col-md-8 col-9">
                                <div className="text-holder">
                                  <h3>
                                    Tips for Choosing the Right Auto Insurance
                                  </h3>
                                  <p>
                                    <FontAwesomeIcon
                                      className="calender-icon"
                                      icon={faCalendar}
                                    />
                                    12 Jan 2024
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 card-after-border">
                          <div className="recent_Blog_card_holder ">
                            <div className="row">
                              <div className="col-lg-3 col-md-4 col-3">
                                <div className="img-holder">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/image/Blog/Blog_main_card/Blog-card-img.png"
                                    }
                                    className="recent_Blog_card_img"
                                    alt="recent_Blog_card_img"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-9 col-md-8 col-9">
                                <div className="text-holder">
                                  <h3>
                                    Tips for Choosing the Right Auto Insurance
                                  </h3>
                                  <p>
                                    <FontAwesomeIcon
                                      className="calender-icon"
                                      icon={faCalendar}
                                    />
                                    12 Jan 2024
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog_Main_Content;
