import React from "react";
import "./Footer.css";
import { Link, NavLink } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
const Footer = () => {
  return (
    <>
      <section className="main-footer">
        <Row>
          <Col lg={12}>
            <Link to={"/"}>
              <div className="text-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/image/Logo/hitech-logo.png"
                  }
                  className="footer-logo-img"
                  alt="..."
                />
              </div>
            </Link>
          </Col>
          <Col lg={12}>
            <div className="footer-links ">
              <NavLink to="/" activeClassName="active">
                {" "}
                <p className={"footer-nav-links"}> Home</p>
              </NavLink>
              <NavLink
                to="/about-us"
                className={"footer-nav-links"}
                activeClassName="active"
              >
                {" "}
                <p className={"footer-nav-links"}> About Us </p>
              </NavLink>
              <NavLink
                to="/four-wheeler-insurance"
                className={"footer-nav-links"}
                activeClassName="active"
              >
                {" "}
                <p className={"footer-nav-links"}>Our Services </p>
              </NavLink>
              <NavLink
                to="/blogs"
                className={"footer-nav-links"}
                activeClassName="active"
              >
                {" "}
                <p className={"footer-nav-links"}> Blogs </p>
              </NavLink>
              <NavLink
                to="/contact-us"
                className={"footer-nav-links"}
                activeClassName="active"
              >
                {" "}
                <p className={"footer-nav-links"}> Contact</p>
              </NavLink>
              <NavLink
                to="/terms-conditions"
                className={"footer-nav-links"}
                activeClassName="active"
              >
                {" "}
                <p className={"footer-nav-links"}> Terms And Conditions </p>
              </NavLink>
              <NavLink
                to="/privacy-policy"
                className={"footer-nav-links"}
                activeClassName="active"
              >
                {" "}
                <p className={"footer-nav-links"}> Privacy Policy </p>
              </NavLink>
              <NavLink
                to="/testimonials"
                className={"footer-nav-links"}
                activeClassName="active"
              >
                {" "}
                <p className={"footer-nav-links"}> Testimonials </p>
              </NavLink>
            </div>
          </Col>
          <Col lg={12}>
            <div className="last-footer-sec mt-5">
              <p className="footer-blue-txtt">
                © 2024 All Rights Reserved Terms Of Use | Privacy Policy Powered
                by{" "}
                <Link target="_black" to={"https://profcyma.com/"}>
                  <span>Profcyma</span>
                </Link>
              </p>
              <div className="second-last-footer">
                <div className="blue-border">
                  <p className="footer-txtt-social">
                    <FontAwesomeIcon
                      icon={faPhoneVolume}
                      className="social-icon-footer me-1"
                    />{" "}
                    +91 9845348745
                  </p>
                </div>
                <p className="footer-txtt-social">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="social-icon-footer me-1"
                  />{" "}
                  hitechsolutions2007@gmail.com
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Footer;
