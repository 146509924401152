import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Navbar } from "react-bootstrap";
import {
  faEnvelope,
  faLocationDot,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
import Nav from "react-bootstrap/Nav";

function Header() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home");

  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);

  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);

    // Close the navbar in mobile view with transition effect
    const navbarToggle = document.querySelector(".navbar-toggler");
    const navbarCollapse = document.querySelector(".navbar-collapse");

    if (navbarToggle && navbarCollapse && window.innerWidth <= 992) {
      navbarCollapse.classList.add("collapsing-transition");
      setTimeout(() => {
        navbarToggle.click();
        navbarCollapse.classList.remove("collapsing-transition");
      }, 300);
    }
  };
  return (
    <>
      <section className="main-header">
        <div className="black-header-top-bg">
          <div className="container">
            <div className="black-header-top">
              <Link to={"/"}>
                <Navbar.Brand>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/image/Logo/hitech-logo.png"
                    }
                    className="logo-img"
                    alt="..."
                    onClick={() => handleNavItemClick("/")}
                  />
                </Navbar.Brand>
              </Link>
              <div className="d-flex">
                <p className="header-txttt me-4">
                  <FontAwesomeIcon
                    icon={faPhoneVolume}
                    className="social-iconn me-2"
                  />
                  +91 9845348745
                </p>
                <p className="header-txttt me-4 ">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="social-iconn me-2"
                  />
                  hitechsolutions2007@gmail.com
                </p>
                <p className="header-txttt me-4 ">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className="social-iconn me-2"
                  />{" "}
                  301, 2nd Floor, 3rd Cross, 6th Main Rd, HRBR Layout,
                  <br /> Kalyan Nagar, Bengaluru, Karnataka 560043
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="second-header">
          <div className="container">
            <Navbar collapseOnSelect expand="lg">
              <div className="mobile-view-logo">
                <Link to={"/"}>
                  <Navbar.Brand>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/image/Logo/hitech-logo.png"
                      }
                      className="logo-img"
                      alt="..."
                      onClick={() => handleNavItemClick("/")}
                    />
                  </Navbar.Brand>
                </Link>
              </div>

              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="justify-content-start"
              >
                <Nav>
                  <Nav.Link
                    as={Link}
                    to="/"
                    className={location.pathname === "/" ? "active" : ""}
                    onClick={() => handleNavItemClick("/")}
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/about-us"
                    className={
                      location.pathname === "/about-us" ? "active" : ""
                    }
                    onClick={() => handleNavItemClick("about-us")}
                  >
                    About Us
                  </Nav.Link>
                  {/* <Nav.Link
                    onClick={() => {
                      handleNavItemClick("/");
                    }}
                  >
                    Our Services
                  </Nav.Link> */}

                  <Dropdown className="dropdown-holder">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className={
                        location.pathname.includes("/four-wheeler-insurance") ||
                        location.pathname.includes("/two-wheeler-insurance") ||
                        location.pathname.includes("/other") ||
                        location.pathname.includes("/health-insurance") ||
                        location.pathname.includes("/travel-insurance") ||
                        location.pathname.includes("/home-insurance")
                          ? "dropdown-btn-holder active"
                          : "dropdown-btn-holder "
                      }
                    >
                      Our Services
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        as={Link}
                        to="/four-wheeler-insurance"
                        onClick={() =>
                          handleNavItemClick("/four-wheeler-insurance")
                        }
                      >
                        <div className="dropdown-menu-holder">
                          <div className="dropdown-menu-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/Header/car.png"
                              }
                              className="dropdown-menu-img"
                              alt="dropdown-menu-img"
                            />
                          </div>
                          <p>4 Wheeler Insurance</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/two-wheeler-insurance"
                        onClick={() =>
                          handleNavItemClick("/two-wheeler-insurance")
                        }
                      >
                        <div className="dropdown-menu-holder">
                          <div className="dropdown-menu-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/Header/bike.png"
                              }
                              className="dropdown-menu-img"
                              alt="dropdown-menu-img"
                            />
                          </div>
                          <p>2 Wheeler Insurance</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/other"
                        onClick={() => handleNavItemClick("/other")}
                      >
                        <div className="dropdown-menu-holder">
                          <div className="dropdown-menu-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/Header/other.png"
                              }
                              className="dropdown-menu-img"
                              alt="dropdown-menu-img"
                            />
                          </div>
                          <p>Other</p>
                        </div>
                      </Dropdown.Item>

                      <Dropdown.Item
                        as={Link}
                        to="/health-insurance"
                        onClick={() => handleNavItemClick("/health-insurance")}
                      >
                        <div className="dropdown-menu-holder">
                          <div className="dropdown-menu-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/Header/health-insurance.png"
                              }
                              className="dropdown-menu-img"
                              alt="dropdown-menu-img"
                            />
                          </div>
                          <p>Health Insurance</p>
                        </div>
                      </Dropdown.Item>

                      <Dropdown.Item
                        as={Link}
                        to="/travel-insurance"
                        onClick={() => handleNavItemClick("/travel-insurance")}
                      >
                        <div className="dropdown-menu-holder">
                          <div className="dropdown-menu-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/Header/travel-insurance.png"
                              }
                              className="dropdown-menu-img"
                              alt="dropdown-menu-img"
                            />
                          </div>
                          <p>Travel Insurance</p>
                        </div>
                      </Dropdown.Item>

                      <Dropdown.Item
                        as={Link}
                        to="/home-insurance"
                        onClick={() => handleNavItemClick("/home-insurance")}
                      >
                        <div className="dropdown-menu-holder">
                          <div className="dropdown-menu-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/Header/home.png"
                              }
                              className="dropdown-menu-img"
                              alt="dropdown-menu-img"
                            />
                          </div>
                          <p>Home Insurance</p>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Nav.Link
                    as={Link}
                    to="/blogs"
                    className={
                      location.pathname.includes("/blogs") ||
                      location.pathname.includes("/blog-inner")
                        ? "active"
                        : ""
                    }
                    onClick={() => handleNavItemClick("blogs")}
                  >
                    Blogs
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/contact-us"
                    className={
                      location.pathname === "/contact-us" ? "active" : ""
                    }
                    onClick={() => handleNavItemClick("contact-us")}
                  >
                    Contact Us
                  </Nav.Link>
                </Nav>
                <div className="mobile-view-divv mt-3">
                  <div>
                    <p className="header-txttt me-4">
                      <FontAwesomeIcon
                        icon={faPhoneVolume}
                        className="social-iconn me-2"
                      />
                      +91 9845348745
                    </p>
                    <p className="header-txttt me-4 ">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="social-iconn me-2"
                      />
                      hitechsolutions2007@gmail.com
                    </p>
                    <p className="header-txttt me-4 ">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        className="social-iconn me-2"
                      />{" "}
                      301, 2nd Floor, 3rd Cross, 6th Main Rd, HRBR Layout,
                      Kalyan Nagar, Bengaluru, Karnataka 560043
                    </p>
                  </div>
                </div>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </section>
    </>
  );
}

export default Header;
