import React, { useState } from "react";
import "./Testimonials_Content.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownWideShort,
  faArrowUpWideShort,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import { Grid, Navigation, Autoplay } from "swiper/modules";
import Happy_Client_Card from "../../Common_component/Happy_Client_Card/Happy_Client_Card";
import parse from 'html-react-parser';
function Testimonials_Content({ dataBrand, IMG_URL }) {
  // State for handling which cards to show and heading text
  const [showTopRated, setShowTopRated] = useState(true);
  const [isSorted, setIsSorted] = useState(false); // For toggling the sort icon

  // Testimonials data
  const testimonialsData = [
    {
      isActive: true,
      feedback:
        "The customer service at Hitech solutions is outstanding. They answered all my questions and guided me through every step of getting my bike insurance. I feel well-protected and valued.",
      clientName: "Nita Ambani",
      imgSrc: "/assets/image/Testimonials/Testimonials_card/sign.png",
      clientImgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Happy_Clients/client-img.png",
    },
    {
      isActive: false,
      feedback:
        "The customer service at Hitech solutions is outstanding. They answered all my questions and guided me through every step of getting my bike insurance. I feel well-protected and valued.",
      clientName: "Nita Ambani",
      imgSrc: "/assets/image/Testimonials/Testimonials_card/white-sign.png",
      clientImgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Happy_Clients/client-img.png",
    },

    {
      isActive: true,
      feedback:
        "The customer service at Hitech solutions is outstanding. They answered all my questions and guided me through every step of getting my bike insurance. I feel well-protected and valued.",
      clientName: "Nita Ambani",
      imgSrc: "/assets/image/Testimonials/Testimonials_card/sign.png",
      clientImgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Happy_Clients/client-img.png",
    },
    {
      isActive: false,
      feedback:
        "The customer service at Hitech solutions is outstanding. They answered all my questions and guided me through every step of getting my bike insurance. I feel well-protected and valued.",
      clientName: "Nita Ambani",
      imgSrc: "/assets/image/Testimonials/Testimonials_card/white-sign.png",
      clientImgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Happy_Clients/client-img.png",
    },

    {
      isActive: true,
      feedback:
        "The customer service at Hitech solutions is outstanding. They answered all my questions and guided me through every step of getting my bike insurance. I feel well-protected and valued.",
      clientName: "Nita Ambani",
      imgSrc: "/assets/image/Testimonials/Testimonials_card/sign.png",
      clientImgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Happy_Clients/client-img.png",
    },
    {
      isActive: false,
      feedback:
        "The customer service at Hitech solutions is outstanding. They answered all my questions and guided me through every step of getting my bike insurance. I feel well-protected and valued.",
      clientName: "Nita Ambani",
      imgSrc: "/assets/image/Testimonials/Testimonials_card/white-sign.png",
      clientImgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Happy_Clients/client-img.png",
    },

    {
      isActive: true,
      feedback:
        "The customer service at Hitech solutions is outstanding. They answered all my questions and guided me through every step of getting my bike insurance. I feel well-protected and valued.",
      clientName: "Nita Ambani",
      imgSrc: "/assets/image/Testimonials/Testimonials_card/sign.png",
      clientImgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Happy_Clients/client-img.png",
    },
    {
      isActive: false,
      feedback:
        "The customer service at Hitech solutions is outstanding. They answered all my questions and guided me through every step of getting my bike insurance. I feel well-protected and valued.",
      clientName: "Nita Ambani",
      imgSrc: "/assets/image/Testimonials/Testimonials_card/white-sign.png",
      clientImgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Happy_Clients/client-img.png",
    },

    {
      isActive: true,
      feedback:
        "The customer service at Hitech solutions is outstanding. They answered all my questions and guided me through every step of getting my bike insurance. I feel well-protected and valued.",
      clientName: "Nita Ambani",
      imgSrc: "/assets/image/Testimonials/Testimonials_card/sign.png",
      clientImgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Happy_Clients/client-img.png",
    },
    {
      isActive: false,
      feedback:
        "The customer service at Hitech solutions is outstanding. They answered all my questions and guided me through every step of getting my bike insurance. I feel well-protected and valued.",
      clientName: "Nita Ambani",
      imgSrc: "/assets/image/Testimonials/Testimonials_card/white-sign.png",
      clientImgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Happy_Clients/client-img.png",
    },

    {
      isActive: true,
      feedback:
        "The customer service at Hitech solutions is outstanding. They answered all my questions and guided me through every step of getting my bike insurance. I feel well-protected and valued.",
      clientName: "Nita Ambani",
      imgSrc: "/assets/image/Testimonials/Testimonials_card/sign.png",
      clientImgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Happy_Clients/client-img.png",
    },
    {
      isActive: false,
      feedback:
        "The customer service at Hitech solutions is outstanding. They answered all my questions and guided me through every step of getting my bike insurance. I feel well-protected and valued.",
      clientName: "Nita Ambani",
      imgSrc: "/assets/image/Testimonials/Testimonials_card/white-sign.png",
      clientImgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Happy_Clients/client-img.png",
    },

    {
      isActive: true,
      feedback:
        "The customer service at Hitech solutions is outstanding. They answered all my questions and guided me through every step of getting my bike insurance. I feel well-protected and valued.",
      clientName: "Nita Ambani",
      imgSrc: "/assets/image/Testimonials/Testimonials_card/sign.png",
      clientImgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Happy_Clients/client-img.png",
    },
    {
      isActive: false,
      feedback:
        "The customer service at Hitech solutions is outstanding. They answered all my questions and guided me through every step of getting my bike insurance. I feel well-protected and valued.",
      clientName: "Nita Ambani",
      imgSrc: "/assets/image/Testimonials/Testimonials_card/white-sign.png",
      clientImgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Happy_Clients/client-img.png",
    },
  ];

  // Function to toggle between showing top-rated or all testimonials and update icon
  const handleSortClick = () => {
    setShowTopRated(!showTopRated);
    setIsSorted(!isSorted);
  };
  return (
    <section className="Testimonials_Content">
      <div className="container">
        <div className="Testimonials_Content_holder">
          <div className="sort-heading-holder">
            <div className="heading-holder">
              {/* Change heading text based on state */}
              <h3>{showTopRated ? "Top Rated" : "All"}</h3>
            </div>

            <div className="sort-holder">
              <h3>
                Sort By{" "}
                <FontAwesomeIcon
                  className={`sort-icon ${isSorted ? "rotate-icon" : ""}`}
                  icon={isSorted ? faArrowDownWideShort : faArrowUpWideShort}
                  onClick={handleSortClick}
                />
              </h3>
            </div>
          </div>

          <div className="Testimonials_card_section">
            <Swiper
              slidesPerView={3}
              grid={{ rows: 2 }}
              spaceBetween={30}
              modules={[Grid, Navigation, Autoplay]}
              navigation={true}

              //   loop={true}
              //   autoplay={{
              //     delay: 2500,
              //     disableOnInteraction: false,
              //   }}
              className="Testimonials_Slider"
              breakpoints={{
                // Define breakpoints for different screen sizes
                320: {
                  slidesPerView: 1, // For small screens (mobile)
                  grid: { rows: 1 }, // Only 1 row for mobile
                },
                767: {
                  slidesPerView: 2, // For medium screens (tablets)
                  grid: { rows: 2 }, // 2 rows for tablets
                  spaceBetween: 20, // Adjust space between slides
                },
                1024: {
                  slidesPerView: 3, // For larger screens (desktop)
                  grid: { rows: 2 }, // 2 rows for desktop
                  spaceBetween: 30, // Default space between slides
                },
              }}
            >
              {/* Render only the cards based on the selected view */}
              {dataBrand
                // .filter((testimonial) =>
                //   showTopRated ? testimonial.isActive : true
                // )
                .map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <Happy_Client_Card
                      // isActive={testimonial.isActive}
                      // feedback={testimonial.feedback}
                      // clientName={testimonial.clientName}
                      // imgSrc={testimonial.imgSrc}
                      // clientImgSrc={testimonial.clientImgSrc}
                      isActive={index % 2 === 0}
                      feedback={parse(testimonial?.description)}
                      clientName={testimonial?.name}
                      imgSrc="/assets/image/Testimonials/Testimonials_card/white-sign.png"
                      clientImgSrc={
                        IMG_URL +
                        testimonial?.image}
                    />
                  </SwiperSlide>
                ))}
              {/* {testimonialsData
                .filter((testimonial) =>
                  showTopRated ? testimonial.isActive : true
                )
                .map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <Happy_Client_Card
                      isActive={testimonial.isActive}
                      feedback={testimonial.feedback}
                      clientName={testimonial.clientName}
                      imgSrc={testimonial.imgSrc}
                      clientImgSrc={testimonial.clientImgSrc}
                    />
                  </SwiperSlide>
                ))} */}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials_Content;
