import React from "react";
import "./Blog_card.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Blog_card({data,IMG_URL}) {
  console.log(data,"data data");
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return an empty string if no date is provided
  
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",     // Day with leading zeros (01, 02, 12, etc.)
      month: "short",     // Abbreviated month (Jan, Feb, etc.)
      year: "numeric",    // Full year (2024)
    });
  };
  return (
    <>
      <Link to={`/blog-inner/${data?.id}`}>
        <section className="Blog_card">
          <div className="container">
            <div className="Blog_card_holder">
              <div className="card-img-holder">
                <div className="img-holder">
                  <img
                    src={
                      IMG_URL+data?.image
                    }
                    className="Blog_card_img"
                    alt="Blog_card_img"
                  />
                </div>
                <div className="overlay-holder"></div>

                <div className="text-holder">
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      className="text-icon"
                      icon={faCalendar}
                    />{" "}
                   {formatDate(data?.created_at)}
                  </p>
                </div>
              </div>

              <div className="card-content-holder">
                <h3>{data?.title}</h3>
                <p>
                 {data?.sub_title}
                </p>
                <div className="read-more_holder">
                  <p className="read-more-text">Read More</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Link>
    </>
  );
}

export default Blog_card;
