import React, { useState } from "react";
import Other_Services_main_card from "../../../../../Common_component/Other_Services_main_card/Other_Services_main_card";
import Get_Quote_Modal from "../../../../../Common_component/Get_Quote_Modal/Get_Quote_Modal";

function Business_Insurance_Card() {
  const [modalShow, setModalShow] = React.useState(false);
  const [insuranceType, setInsuranceType] = useState('');

  const handleQuoteModal = (type) => {
   
    setInsuranceType(type);
    setModalShow(true); // Open Get_Quote_Modal
    // props.onHide(); // Close Other_Insurance_Modal
   
  };
  return (
    <>
      <section className="Other_card Other_Inner_Card">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="other_card_holder">
                <Other_Services_main_card
                  cardImg={
                    "/assets/image/Services/Others/Business_Inner_card/marien-ship.png"
                  }
                  cardText={"Marine Cargo Insurance"}
                  onClick={() => handleQuoteModal("Other-BI-MCR")}
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="other_card_holder">
                <Other_Services_main_card
                  cardImg={
                    "/assets/image/Services/Others/Business_Inner_card/health-insurance.png"
                  }
                  cardText={"Group Health Insurance"}
                  onClick={() => handleQuoteModal("Other-BI-GHI")}
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="other_card_holder">
                <Other_Services_main_card
                  cardImg={
                    "/assets/image/Services/Others/Business_Inner_card/fire.png"
                  }
                  cardText={"Fire & Burglary Insurance"}
                  onClick={() => handleQuoteModal("Other-BI-FBI")}
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="other_card_holder">
                <Other_Services_main_card
                  cardImg={
                    "/assets/image/Services/Others/Business_Inner_card/marien-beautiful.png"
                  }
                  cardText={"Marine open Insurance"}
                  onClick={() => handleQuoteModal("Other-BI-MOI")}
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="other_card_holder">
                <Other_Services_main_card
                  cardImg={
                    "/assets/image/Services/Others/Business_Inner_card/workmen.png"
                  }
                  cardText={"Workmen Compensation Insurance"}
                  onClick={() => handleQuoteModal("Other-BI-WCI")}
                />
              </div>
            </div>


            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="other_card_holder">
                <Other_Services_main_card
                  cardImg={
                    "/assets/image/Services/Others/Business_Inner_card/workmen.png"
                  }
                  cardText={"Group Medicare Policy"}
                  onClick={() => setModalShow(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Get_Quote_Modal insuranceType={insuranceType} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default Business_Insurance_Card;
