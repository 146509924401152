import React from "react";
import "./Testimonials_banner.css";

function Testimonials_banner() {
  return (
    <>
      <section className="Testimonials_banner">
        <div className="Testimonials_banner_holder">
          <div className="img-holder">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/image/Testimonials/Testimonials_banner/Testimonials-banner-img.png"
              }
              className="Testimonials_banner_img"
              alt="Testimonials_banner_img"
            />
          </div>
          <div className="overlay-holder"></div>
          <div className="text-holder">
            <div className="container">
              <h3>Customer Success Stories</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonials_banner;
