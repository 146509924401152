import React from "react";
import How_to_Buy_card from "../../../Common_component/How_to_Buy_card/How_to_Buy_card";

function Two_Wheeler_How_to_Buy({ howToBuyPlanHeading }) {
  return (
    <>
      <section className="How_to_Buy">
        <div className="container">
          <div className="How_to_Buy-holder">
            <div className="section-heading-holder">
              <h3>{howToBuyPlanHeading}</h3>
            </div>

            <div className="How_to_Buy_card_holder">
              <div className="card_holder">
                <How_to_Buy_card
                  cardNumber={"1"}
                  cardHeading={"Contact Us:"}
                  content={
                    " Reach out to us to determine the type of insurance you're looking for, whether it's third-party liability, comprehensive, or personal accident cover."
                  }
                />
              </div>

              <div className="card_holder">
                <How_to_Buy_card
                  cardNumber={"2"}
                  cardHeading={"Share Your Needs: "}
                  content={
                    " Provide your previous policy copy and discuss your requirements with our insurance experts to find the best-suited options based on your needs and budget."
                  }
                />
              </div>

              <div className="card_holder">
                <How_to_Buy_card
                  cardNumber={"3"}
                  cardHeading={"Get a Quote"}
                  content={
                    " Review the policy details carefully, select the one that fits you best, complete the application process, and enjoy peace of mind with full coverage!"
                  }
                />
              </div>

              <div className="card_holder">
                <How_to_Buy_card
                  cardNumber={"4"}
                  cardHeading={"Choose and Enjoy Coverage:"}
                  content={
                    " Review the policy details carefully, select the one that fits you best, complete the application process, and enjoy peace of mind with full coverage!"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Two_Wheeler_How_to_Buy;
