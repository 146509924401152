import React, { useState } from "react";
import "./Our_Top_Plan_card.css";
import { Button } from "react-bootstrap";
import Get_Quote_Modal from "../Get_Quote_Modal/Get_Quote_Modal";

function Our_Top_Plan_card({
  cardColor,
  locationText,
  companyText,
  pricePremiumText,
  fromPrice,
  toPrice,
  planBenefits,
  noteText,
}) {
  const [modalShow, setModalShow] = React.useState(false);

  const [insuranceType, setInsuranceType] = useState("");
  const handleQuoteModal = (type) => {
    console.log(type, "type ttttttttttttttt");

    setInsuranceType(type);
    setModalShow(true); // Open Get_Quote_Modal
    // props.onHide(); // Close Other_Insurance_Modal
  };

  return (
    <>
      <section
        className="Our_Top_Plan_card"
        style={{ backgroundColor: cardColor }}
      >
        <div className="card-heading">
          <h3>{locationText}</h3>
        </div>

        <div className="plan-holder">
          <h4>
            {companyText} <br />
          </h4>

          <h2> {pricePremiumText}</h2>

          <h6>Premium Start at</h6>

          <h6>
            <b>
              Coverage from {fromPrice} to {toPrice}
            </b>
          </h6>
        </div>

        <div className="plan-text-holder">
          {planBenefits.map((benefit, index) => (
            <div className="text-holder" key={index}>
              <div className="icon-holder">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/image/Services/Heath_Insuarance/Plans/shield.png"
                  }
                  className="plan-icon-img"
                  alt="plan-icon-img"
                />
              </div>
              <p>{benefit}</p>
            </div>
          ))}
        </div>

        <div className="border-holder"></div>
        <div className="note-holder  mt-3">
          <p>{noteText}</p>
        </div>

        <div className="Get_quote_btn_holder">
          <Button
            className="Get-quote-btn"
            onClick={() => handleQuoteModal("Health")}
          >
            Get Quote
          </Button>
        </div>
      </section>

      <Get_Quote_Modal
        insuranceType={insuranceType}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Our_Top_Plan_card;
