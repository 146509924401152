import React from "react";
import "./WhyIsHitech.css";
import { Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";

const slideData = [
  {
    imgSrc: "/assets/image/Homepage/india.png",
    imgSrcTwo: "/assets/image/Homepage/india2.png",
    imgAltTwo: "Hitech-Solutions-img",
    imgAlt: "Hitech-Solutions-img",
    title: "Trusted by Thousands Across India",
    description:
      "Hitech Solutions is trusted nationwide for its reliable and customer-entered service, earning the trust and satisfaction of thousands of clients.",
  },
  {
    imgSrc: "/assets/image/Homepage/www.png",
    imgSrcTwo: "/assets/image/Homepage/www2.png",
    imgAltTwo: "Hitech-Solutions-img",
    imgAlt: "Hitech-Solutions-img",
    title: "Wide Range of Insurance Options",
    description:
      "We offer different insurance plans for vehicles, health, property, travel, and businesses. Each plan is customized to fit your needs.",
  },
  {
    imgSrc: "/assets/image/Homepage/setting.png",
    imgSrcTwo: "/assets/image/Homepage/setting2.png",
    imgAltTwo: "Hitech-Solutions-img",
    imgAlt: "Hitech-Solutions-img",
    title: "Seamless & hassle-free process",
    description:
      "Our professional support and expert advice make it easier for customers to buy and manage insurance quickly.",
  },
  {
    imgSrc: "/assets/image/Homepage/money.png",
    imgSrcTwo: "/assets/image/Homepage/money2.png",
    imgAltTwo: "Hitech-Solutions-img",
    imgAlt: "Hitech-Solutions-img",
    title: "Affordable Premiums, Great Benefits",
    description:
      "We offer affordable premiums with extensive coverage, ensuring you get the best value and robust protection for all your needs.",
  },
];

const WhyIsHitech = () => {
  return (
    <section className="why-is-hitech">
      <div className="section-heading-holder">
        <h3>Why is Hitech Solutions </h3>
        <p>One of India’s Top Choices for Buying Insurance?</p>
      </div>
      <Container>
        <Row>
          <Swiper
            spaceBetween={30}
            modules={[Autoplay]}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              0: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              991: { slidesPerView: 3 },
              1440: { slidesPerView: 4 },
            }}
            className="myswiper WhyIsHitech-swiper"
          >
            {slideData.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="text-center">
                  <div className="WhyIsHitech-img-holder WhyIsHitech-img ">
                    <img
                      src={process.env.PUBLIC_URL + slide.imgSrc}
                      className="inshurance-img before-hover"
                      alt={slide.imgAlt}
                    />

                    <img
                      src={process.env.PUBLIC_URL + slide.imgSrcTwo}
                      className="inshurance-img after-hover"
                      alt={slide.imgAltTwo}
                    />
                  </div>

                  <h5 className="title-inshu">{slide.title}</h5>
                  <p className="common-text">{slide.description}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Row>
      </Container>
    </section>
  );
};

export default WhyIsHitech;
