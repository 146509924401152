import React from "react";
import "./Why_Choose_Us.css";

function Why_Choose_Us() {
  // Step 1: Create an array of card data
  const cardData = [
    {
      title: "Wide Range of Insurance Options",
      description:
        "We offer various insurance plans, including health, motor, home, travel, employee compensation, and business coverage. This means you can find the right protection tailored to your unique situation in one place.",
      image: "/assets/image/About_Us/Why_Choose_Us/insurance.png",
    },
    {
      title: "Expert Guidance",
      description:
        "Our knowledgeable team offers expert guidance to help you choose the right insurance plans. We take the time to understand your unique needs, ensuring you get personalized advice and the best solutions for your situation.",
      image: "/assets/image/About_Us/Why_Choose_Us/guidance.png",
    },
    {
      title: "Competitive pricing",
      description:
        "Hitech Solutions offers competitive pricing on all our insurance plans, ensuring you receive excellent coverage at an affordable rate. This means you get the best value for your investment without compromising on quality.",
      image: "/assets/image/About_Us/Why_Choose_Us/reward.png",
    },
    {
      title: "Simple and Fast Process",
      description:
        "We have streamlined our insurance process to make it easy and quick for you to get the coverage you need. From purchasing to claims, we ensure a hassle-free experience, saving you time and effort.",
      image: "/assets/image/About_Us/Why_Choose_Us/express.png",
    },
  ];

  return (
    <>
      <section className="Why_Choose_Us">
        <div className="container">
          <div className="Why_Choose_Us_holder">
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <div className="section-heading-holder">
                  <h3>Why Choose Us</h3>
                  <p>Comprehensive Insurance Solutions Tailored for You</p>
                  <h1>?</h1>
                </div>
              </div>

              <div className="col-lg-9 col-md-12">
                <div className="Why_Choose_Us_card_holder">
                  <div className="row">
                    {/* Step 2: Use map to render cards dynamically */}
                    {cardData.map((card, index) => (
                      <div className="col-md-6 mb-4" key={index}>
                        <div className="Why_Choose_Us_card">
                          <div className="row">
                            <div className="col-md-3 col-sm-2  col-3">
                              <div className="Why_Choose_Us_img_holder">
                                <img
                                  src={process.env.PUBLIC_URL + card.image}
                                  className="Why_Choose_Us_img"
                                  alt="Why_Choose_Us_img"
                                />
                              </div>
                            </div>
                            <div className="col-md-9 col-sm-10  col-9">
                              <div className="text-holder">
                                <h3>{card.title}</h3>
                                <p>{card.description}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Why_Choose_Us;
