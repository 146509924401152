import React from "react";
import "./Call_Icon.css";

function Call_Icon() {
  return (
    <>
      <section className="Call_Icon">
        <div className="icon-name-holder">
          <div className="img-holder">
            <img
              src={process.env.PUBLIC_URL + "/assets/image/Icon/whatsapp.png"}
              className="call-img"
              alt="whatsapp"
            />
          </div>

          <div className="name-holder">
            <div className="text-holder">
              <p>Chat With us</p>
            </div>
          </div>
        </div>

        <div className="icon-name-holder">
          <div className="img-holder">
            <img
              src={process.env.PUBLIC_URL + "/assets/image/Icon/call.png"}
              className="call-img"
              alt="call"
            />
          </div>

          <div className="name-holder">
            <div className="text-holder">
              <p>Call us </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Call_Icon;
