import React, { useEffect } from "react";
import "./Bottom_Successfull_Modal.css";
import { Modal } from "react-bootstrap";

function Bottom_Successfull_Modal(props) {
  const { text, onHide } = props; // Extracting 'message' prop and 'onHide' method

  useEffect(() => {
    // Set a timeout to close the modal after 3 seconds
    const timer = setTimeout(() => {
      onHide(); // Close the modal
    }, 3000);

    // Cleanup the timer if the component unmounts before the timeout
    return () => clearTimeout(timer);
  }, [onHide]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        className="Bottom_Successfull_Modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Footer>
          <div className="green-patch">
            <img
              src={process.env.PUBLIC_URL + "/assets/image/Icon/green-patch.png"}
              className="green_patch_img"
              alt="green_patch_img"
            />
          </div>
          {text}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Bottom_Successfull_Modal;
