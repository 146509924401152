import React from "react";

function Health_Insurance_About_us({ aboutHeading }) {
  return (
    <>
      <section className="Four_Wheeler_About_Us ">
        <div className="container">
          <div className="Four_Wheeler_About_Us_holder">
            <div className="section-heading-holder">
              <h3>{aboutHeading}</h3>
            </div>

            <div className="content-holder">
              <p>
                A <b>health insurance policy</b>h protects you from the
                financial strain of a wide variety of health-related expenses,
                from minor illnesses to critical diseases. With the right health
                insurance plan and the cumulative bonus offered, you create a
                protective financial shield for yourself and your loved ones
                when faced with substantial medical expenses.
              </p>
              <p>
                Selecting the right <b>medical insurance</b> can be difficult
                but is crucial for ensuring you're adequately prepared for any
                sudden or expected medical expenses. Imagine being diagnosed
                with a serious medical condition that requires long-term
                treatment, hospitalization, or surgery. Or take the example of
                the COVID-19 pandemic in India—consider the expenses you might
                face if diagnosed with such an infection.
              </p>{" "}
              <p>
                In such situations, the last thing you want is to have your
                medical bills drain your savings or cause a financial crisis.
                This is why purchasing the right <b>health insurance plan</b>,
                selecting the appropriate sum insured, and paying the premium
                promptly are vital steps to safeguard your financial future.
              </p>
              <p>
                We’re here to guide you through every step of the process to
                ensure you're insured at the earliest and ready for whatever
                life may bring.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Health_Insurance_About_us;
