import React from "react";
import How_to_Buy from "../Four_Wheeler_Insurance/How_to_Buy/How_to_Buy";
import Home_Insurance_About_us from "./Home_Insurance_About_us/Home_Insurance_About_us";
import Our_Top_Plan from "./Our_Top_Plan/Our_Top_Plan";
import Home_Wheeler_Insurance_Banner from "./Home_Wheeler_Insurance_Banner/Home_Wheeler_Insurance_Banner";
import Why_Need_Home_Insurance from "./Why_Need_Home_Insurance/Why_Need_Home_Insurance";
import Why_Need_Health_Insurance from "../Health_Insurance/Why_Need_Health_Insurance/Why_Need_Health_Insurance";

function Home_Insurance() {
  return (
    <>
      <Home_Wheeler_Insurance_Banner
        imgSrc={
          "/assets/image/Services/Wheeler_Service_Banner/Heath-banner.png"
        }
        formText={"Home Insurance"}
        bannerText={"Home Insurance"}
      />

      <Home_Insurance_About_us aboutHeading={"About Home Insurance"} />

      <Why_Need_Home_Insurance />

      <Our_Top_Plan />

      <How_to_Buy howToBuyPlanHeading={"How to buy a Home insurance plan"} />
    </>
  );
}

export default Home_Insurance;
