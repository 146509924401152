import React, { useState, useEffect, useContext } from "react";
import "./Generate_Quick_Inquiry.css";
import { Button, Form } from "react-bootstrap";
import Bottom_Successfull_Modal from "../../Common_component/Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import Common_btn_holder from "../../Common_component/Common_btn/Common_btn_holder";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { postInsurance } from "../../../utils/apis/common/Common";
function Generate_Quick_Inquiry() {
  const [modalShow, setModalShow] = React.useState(false);

  const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();

  const onSubmit = async (data) => {
    console.log(data, "data data");
    const formattedData = {

      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      message: data.message,
      contact_number: data.contact_number,
      type: 'Quick Inquiry',

    };
    const res = await postInsurance(formattedData)
    if (res?.success) {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        reset();
      }, 3000);

    } else {
      // if (res?.message?.email) {
      //   setError("email", { message: res?.message?.email });
      // }
      // if (res?.message?.contact_no) {
      //   setError("contact_no", { message: res?.message?.contact_no });
      // }
    }
  }
  return (
    <>
      <section className="Generate_Quick_Inquiry">
        <div className="container">
          <div className="Quick_Inquiry_holder">
            <div className="bg-img-holder">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/image/Homepage/Generate_Quick_Inquiry/Generate-Quick-bg.png"
                }
                className="Generate_Quick-banner-img"
                alt="Generate_Quick-banner-img"
              />
            </div>
            <div className="Inquiry-form-holder">
              <div className="row me-0 ms-0 ">
                <div className="col-lg-6 col-md-8">
                  <div className="form-heading-holder">
                    <h3>Generate Quick Inquiry</h3>
                  </div>
                  <div className="Inquiry-main-form">
                    <Form className="form-holder">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                          <Form.Group
                            className=""
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Enter First Name"
                              {...register("first_name", {
                                required: "First Name is required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.first_name,
                              })}
                              onKeyDown={(event) => {
                                if (!/[A-Z-a-z ]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {errors.first_name && <div className="invalid-feedback">{errors.first_name.message}</div>}
                          </Form.Group>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                          <Form.Group
                            className=""
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Enter Last Name"
                              {...register("last_name", {
                                required: "Last Name is required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.last_name,
                              })}
                              onKeyDown={(event) => {
                                if (!/[A-Z-a-z ]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {errors.last_name && <div className="invalid-feedback">{errors.last_name.message}</div>}
                          </Form.Group>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                          <Form.Group
                            className=""
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              className={classNames("", {
                                "is-invalid": errors?.contact_number,
                                "is-valid": getValues("contact_number"),
                              })}
                              type="text"
                              name="contact_number"
                              placeholder="Enter Mobile Number"
                              {...register("contact_number", {
                                required: "Contact No is required",
                                minLength: {
                                  value: 10,
                                  message:
                                    "Number should be at least 10 characters",
                                },
                              })}
                              onKeyDown={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  if (event.key !== "Backspace") {
                                    event.preventDefault();
                                  }
                                }
                              }}
                              maxLength={10}
                            />
                            {errors?.contact_number && (
                              <sup className="text-danger">
                                {errors?.contact_number?.message}
                              </sup>
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                          <Form.Group
                            className=""
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              type="text"
                              name="email"
                              placeholder="E-mail Address"
                              {...register("email", {
                                required: "Email Id required",
                                pattern: {
                                  value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
                                  message: "Email Id Invalid",
                                },
                              })}
                              className={classNames("", { "is-invalid": errors?.email })}
                            />
                            {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                          </Form.Group>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-field-holder">
                          <Form.Group
                            className=""
                            controlId="exampleForm.ControlInput1"
                          >
                            {/* <Form.Control
                              as="textarea"
                              rows={1}
                              placeholder="Type From Here.."
                            /> */}

                            <Form.Control
                              as="textarea"
                              name="name"
                              rows={1}
                              placeholder="Type From Here.."
                              {...register("message", {
                                required: "message is required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.message,
                              })}

                            />
                            {errors.message && <div className="invalid-feedback">{errors.message.message}</div>}
                          </Form.Group>
                        </div>
                      </div>
                    </Form>

                    <Common_btn_holder
                      onClick={handleSubmit(onSubmit)}
                      // onClick={() => setModalShow(true)}
                      btnText={"Submit"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Bottom_Successfull_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        text={"Inquiry Generated Successfully!!"}
      />
    </>
  );
}

export default Generate_Quick_Inquiry;
