import React from "react";
import { Form } from "react-bootstrap";
import Bottom_Successfull_Modal from "../../../../Common_component/Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import Common_btn_holder from "../../../../Common_component/Common_btn/Common_btn_holder";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { postInsurance } from "../../../../../utils/apis/common/Common";

const ThirdStep = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();

  const onSubmit = async (data) => {
    console.log(data, "data data");
    const formattedData = {
      
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      city: data.city,
      vr_no: data.vr_no,
      contact_number: data.contact_number,
      type:'4 Wheeler',
     
    };
    const res = await postInsurance(formattedData)
    if (res?.success) {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        reset();
      }, 3000);

    } else {
      // if (res?.message?.email) {
      //   setError("email", { message: res?.message?.email });
      // }
      // if (res?.message?.contact_no) {
      //   setError("contact_no", { message: res?.message?.contact_no });
      // }
    }
  }
  return (
    <>
      <section className="main-form">
        <Form className="form-holder">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
              <Form.Group className="" controlId="exampleForm.ControlInput1">
                <Form.Control type="text" placeholder="Enter First Name" />
              </Form.Group>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
              <Form.Group className="" controlId="exampleForm.ControlInput1">
                <Form.Control type="text" placeholder="Enter Last Name" />
              </Form.Group>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
              <Form.Group className="" controlId="exampleForm.ControlInput1">
                <Form.Control
                  type="number"
                  placeholder="Vehicles Registration Number"
                />
              </Form.Group>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
              <Form.Group className="" controlId="exampleForm.ControlInput1">
                <Form.Control type="text" placeholder="Enter City" />
              </Form.Group>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
              <Form.Group className="" controlId="exampleForm.ControlInput1">
                <Form.Control type="tel" placeholder="Mobile Number" />
              </Form.Group>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
              <Form.Group className="" controlId="exampleForm.ControlInput1">
                <Form.Control type="email" placeholder="Enter Email ID" />
              </Form.Group>
            </div>
          </div>
        </Form>

        <Common_btn_holder
          onClick={handleSubmit(onSubmit)}
          // onClick={() => setModalShow(true)}
          btnText={"Get Quote"}
        />
      </section>
   
      <Bottom_Successfull_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        text={"Quote Generated Successfully!"}
      />
    </>
  );
};

export default ThirdStep;
