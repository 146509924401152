import React, { useState } from "react";
import "./Other_Insurance_Modal.css";
import { Modal } from "react-bootstrap";
import Other_Services_main_card from "../Other_Services_main_card/Other_Services_main_card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Get_Quote_Modal from "../Get_Quote_Modal/Get_Quote_Modal";

function Other_Insurance_Modal(props) {
  const [modalShow, setModalShow] = useState(false);
  const [insuranceType, setInsuranceType] = useState('');

  const handleQuoteModal = (type) => {
    console.log(type,'type ttttttttttttttt');
    
    setInsuranceType(type);
    setModalShow(true); // Open Get_Quote_Modal
    props.onHide(); // Close Other_Insurance_Modal
   
  };
  console.log(insuranceType,"insuranceType insuranceType");
  
  return (
    <>
      <section className="Other_Insurance_Modal">
        <Modal
          {...props}
          className="Other_Insurance_Modal all_modal"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="Other_Insurance_Modal">
              <div className="Other_Insurance_Modal_card_holder">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={2}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                  className="Insurance_Services-slider"
                  modules={[Pagination]}
                  pagination={{
                    dynamicBullets: true,
                    clickable:true
                  }}
                  breakpoints={{
                    // when window width is >= 320px
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    // when window width is >= 480px
                    480: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    // when window width is >= 768px
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },

                    // when window width is >= 992px
                    992: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },
                    // when window width is >= 1024px
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },

                    // when window width is >= 1200px
                    1299: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                  }}
                >
                  <SwiperSlide>
                    <div className="Other_Insurance_Modal_card">
                      <Other_Services_main_card
                        cardImg={
                          "/assets/image/Services/Others/Other_main_card/Other-img-1.png"
                        }
                        cardText={"Commercial Vehicles Insurance"}
                      />

                      <div className="img-text-holder">
                        <div className="img-text-card-holder"   onClick={() => handleQuoteModal("Other-CVI-CCI")} >
                          <div className="row">
                            <div className="col-lg-2 col-md-3 col-3">
                              <div className="img-holder">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/image/Services/Others/Commercial_inner_card/Car.png"
                                  }
                                  className="Insurance_img"
                                  alt="Insurance_img"
                                />
                              </div>
                            </div>

                            <div className="col-lg-10 col-md-9 col-9">
                              <div className="text-holder">
                                <p>Commercial Car Insurance</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="img-text-card-holder"  onClick={() => handleQuoteModal("Other-CVI-CAI")}>
                          <div className="row">
                            <div className="col-lg-2 col-md-3 col-3">
                              <div className="img-holder">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/image/Services/Others/Commercial_inner_card/Auto.png"
                                  }
                                  className="Insurance_img"
                                  alt="Insurance_img"
                                />
                              </div>
                            </div>

                            <div className="col-lg-10 col-md-9 col-9 ">
                              <div className="text-holder">
                                <p>Commercial Auto Insurance</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="img-text-card-holder"   onClick={() => handleQuoteModal("Other-CVI-CTI")}>
                          <div className="row">
                            <div className="col-lg-2 col-md-3 col-3">
                              <div className="img-holder">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/image/Services/Others/Commercial_inner_card/Truck.png"
                                  }
                                  className="Insurance_img"
                                  alt="Insurance_img"
                                />
                              </div>
                            </div>

                            <div className="col-lg-10 col-md-9 col-9">
                              <div className="text-holder">
                                <p>Commercial Truck Insurance</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="Other_Insurance_Modal_card">
                      <Other_Services_main_card
                        cardImg={
                      "/assets/image/Services/Others/Other_main_card/Other-img-2.png"
                    }
                    cardText={"Business Insurance"}
                      />

                      <div className="img-text-holder">
                        <div className="img-text-card-holder"  onClick={() => handleQuoteModal("Other-BI-MCR")}>
                          <div className="row">
                            <div className="col-lg-2 col-md-3 col-3">
                              <div className="img-holder">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/image/Services/Others/Business_Inner_card/marien-ship.png"
                                  }
                                  className="Insurance_img"
                                  alt="Insurance_img"
                                />
                              </div>
                            </div>

                            <div className="col-lg-10 col-md-9 col-9">
                              <div className="text-holder">
                                <p>Marine Cargo Insurance</p>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="img-text-card-holder"  onClick={() => handleQuoteModal("Other-BI-GHI")}>
                          <div className="row">
                            <div className="col-lg-2 col-md-3 col-3">
                              <div className="img-holder">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/image/Services/Others/Business_Inner_card/health-insurance.png"
                                  }
                                  className="Insurance_img"
                                  alt="Insurance_img"
                                />
                              </div>
                            </div>

                            <div className="col-lg-10 col-md-9 col-9">
                              <div className="text-holder">
                                <p>Group Health Insurance</p>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="img-text-card-holder"   onClick={() => handleQuoteModal("Other-BI-FBI")} >
                          <div className="row">
                            <div className="col-lg-2 col-md-3 col-3">
                              <div className="img-holder">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/image/Services/Others/Business_Inner_card/fire.png"
                                  }
                                  className="Insurance_img"
                                  alt="Insurance_img"
                                />
                              </div>
                            </div>

                            <div className="col-lg-10 col-md-9 col-9">
                              <div className="text-holder">
                                <p>Fire & Burglary Insurance</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="img-text-card-holder"  onClick={() => handleQuoteModal("Other-BI-MOI")}>
                          <div className="row">
                            <div className="col-lg-2 col-md-3 col-3">
                              <div className="img-holder">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/image/Services/Others/Business_Inner_card/marien-beautiful.png"
                                  }
                                  className="Insurance_img"
                                  alt="Insurance_img"
                                />
                              </div>
                            </div>

                            <div className="col-lg-10 col-md-9 col-9">
                              <div className="text-holder">
                                <p>Marine Open Insurance</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="img-text-card-holder"   onClick={() => handleQuoteModal("Other-BI-WCI")}>
                          <div className="row">
                            <div className="col-lg-2 col-md-3 col-3">
                              <div className="img-holder">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/image/Services/Others/Business_Inner_card/workmen.png"
                                  }
                                  className="Insurance_img"
                                  alt="Insurance_img"
                                />
                              </div>
                            </div>

                            <div className="col-lg-10 col-md-9 col-9">
                              <div className="text-holder">
                                <p>Workmen Compensation Insurance</p>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="img-text-card-holder"  onClick={handleQuoteModal}>
                          <div className="row">
                            <div className="col-lg-2 col-md-3 col-3">
                              <div className="img-holder">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/image/Services/Others/Business_Inner_card/workmen.png"
                                  }
                                  className="Insurance_img"
                                  alt="Insurance_img"
                                />
                              </div>
                            </div>

                            <div className="col-lg-10 col-md-9 col-9">
                              <div className="text-holder">
                                <p>Group Medicare Policy</p>
                              </div>
                            </div>
                          </div>
                        </div>

                       

                       
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </Modal.Body>

          <div className="close-btn-holder" onClick={props.onHide}>
            <FontAwesomeIcon className="close-btn" icon={faXmark} />
          </div>
        </Modal>
      </section>

      <Get_Quote_Modal insuranceType={insuranceType} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default Other_Insurance_Modal;
