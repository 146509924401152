import React from "react";
import Common_banner from "../Common_component/Common_banner/Common_banner";
import Privacy_Policy_Content from "./Privacy_Policy_Content/Privacy_Policy_Content";
import About_Quick_Inquiry from "../About_Us/About_Quick_Inquiry/About_Quick_Inquiry";

function Privacy_Policy() {
  return (
    <>
      <Common_banner
        imgSrc={"/assets/image/Privacy_Policy/Privacy-Policy-banner.png"}
        mainHeading={"Privacy Policy"}
        heading={"Privacy Policy"}
      />

      <Privacy_Policy_Content />

      <About_Quick_Inquiry/>
    </>
  );
}

export default Privacy_Policy;
