import React, { useState, useEffect, useContext } from "react";
import Testimonials_banner from "./Testimonials_banner/Testimonials_banner";
import Testimonials_Content from "./Testimonials_Content/Testimonials_Content";
import { Context } from "../../utils/context";
import { getAllProudtoPartner,getAllDataHappyCustomerData,getSuccess,getBanner } from "../../utils/apis/common/Common";
function Testimonials() {
  const {  IMG_URL } = useContext(Context);
  const [dataBrand, setDataBrand] = useState();
  const getAllDataHappyCustomer = async () => {
    const res = await getAllDataHappyCustomerData();
    
    if (res?.success) {
      setDataBrand(res?.data?.list);
    } else {
        console.error('Error fetching  data:', res?.error);
    }
}

  useEffect(() => {
   
      getAllDataHappyCustomer();
  }, []);
  return (
    <>
      <Testimonials_banner />
      {dataBrand?.length > 0 && <Testimonials_Content dataBrand={dataBrand} IMG_URL={IMG_URL} />}
   
    </>
  );
}

export default Testimonials;
