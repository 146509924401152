import React from "react";

function Why_Need_Home_Insurance() {
  return (
    <>
      <section className="Types_of_Four_Wheeler Why_Need_Health_Insurance">
        <div className="container">
          <div className="Types_of_Four_Wheeler_holder">
            <div className="heading-holder">
              <h3>Why Do I Need Home Insurance?</h3>
            </div>
            <div className="Types_of_Four_Wheeler_content">
              <div className="text-holder main-text-holder">
                <p>
                  Health insurance is an investment in your health and financial
                  future, providing a safety net against the unpredictability of
                  medical expenses.
                </p>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <div className="content-list-holder">
                    <ol>
                      <li>
                        <div className="text-holder">
                          <h3>Financial Security:</h3>
                          <p>
                            Health insurance helps cover the high costs of
                            medical care, from routine check-ups to major
                            surgeries, reducing the burden of unexpected
                            expenses.
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="text-holder">
                          <h3> Access to Quality Care:</h3>
                          <p>
                            With insurance, you can access a network of
                            healthcare providers, ensuring you receive timely
                            and quality medical attention when needed.{" "}
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="text-holder">
                          <h3>Preventive Services:</h3>{" "}
                          <p>
                            Many plans cover preventive services, such as
                            vaccinations and screenings, which can detect health
                            issues early and keep you healthier in the long run.{" "}
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="text-holder">
                          <h3>Managing Chronic Conditions: </h3>{" "}
                          <p>
                            If you have a chronic illness like diabetes or heart
                            disease, insurance helps cover ongoing treatments,
                            medications, and specialist visits.{" "}
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="text-holder">
                          <h3>Peace of Mind:</h3>{" "}
                          <p>
                            Knowing you're covered in case of an emergency or
                            serious illness gives you peace of mind, allowing
                            you to focus on your health and well-being.{" "}
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="text-holder">
                          <h3>Legal Requirements:</h3>
                          <p>
                            In some countries, having health insurance is a
                            legal requirement, and not having it could result in{" "}
                            penalties.
                          </p>
                        </div>
                      </li>

                      <div className="text-holder">
                        <p>
                          Health insurance is an investment in your health and
                          financial future, providing a safety net against the
                          unpredictability of medical expenses.
                        </p>
                      </div>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Why_Need_Home_Insurance;
