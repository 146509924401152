import React from "react";
import Other_card from "./Other_Main/Other_card/Other_card";
import Other_Service_banner from "../../Common_component/Other_Service_banner/Other_Service_banner";

function Other_Service() {
  return (
    <>
      <Other_Service_banner bannerText={"Other Insurance"} />

      <Other_card />
    </>
  );
}

export default Other_Service;
