import React from "react";
import "./About_Hitech.css";
import CountUp from "react-countup";

function About_Hitech() {
  return (
    <>
      <section className="About_Hitech_holder">
        <div className="container">
          <div className="About_Hitech">
            <div className="row">
              <div className="col-md-6 col-sm-12 col-12">
                <div className="heading-holder">
                  <h4>
                    About <span className="heading-text">Hitech Solutions</span>
                  </h4>
                </div>

                <div className="content-text-holder">
                  <div className="text-holder">
                    <p>
                      At Hitech Solutions, we take pride in serving our
                      customers for over 23 years by providing reliable
                      insurance solutions for motor, health, travel, and
                      property. Our strong partnerships with leading insurers
                      like TATA AIG, ICICI Lombard, Go Digit, Royal Sundaram,
                      Shriram General Insurance, and Go Digit allow us to offer
                      personalized insurance plans tailored to your unique
                      needs.
                    </p>
                  </div>
                  <div className="list-holder">
                    <ul>
                      <li>
                        <p>23 Years of Experience</p>
                      </li>
                      <li>
                        <p>Trusted by thousands of customers </p>
                      </li>
                      <li>
                        <p>98% Claim Success Rate </p>
                      </li>
                      <li>
                        <p>Cashless policies</p>
                      </li>
                      <li>
                        <p>Exciting Discounts Cashback</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-6">
                <div className="box-holder">
                  <div className="row">
                    <div className="col-xl-6 col-lg-7  col-md-8 col-sm-8  col-8 border-holder">
                      <div className="rating-box">
                        <div>
                          <p>4.9</p>
                          <p>Ratings</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-10  col-md-11 col-sm-11 col-10 mobile-sec">
                      <div className="gray-box"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-2 col-sm-6 col-6">
                <div className="counter-holder">
                  <div className="counter-text border-bottom-holder">
                    <h4>
                      <CountUp start={0} end={2} duration={5} />{" "}
                      <span>+ L</span>
                    </h4>
                    <p>Customer served</p>
                  </div>

                  <div className="counter-text border-bottom-holder">
                    <h4>
                      <CountUp start={0} end={40} duration={5} /> <span>+</span>
                    </h4>
                    <p>Insurance experts</p>
                  </div>

                  <div className="counter-text">
                    <h4>
                      <CountUp start={0} end={20} duration={5} /> <span>K</span>
                    </h4>
                    <p className="bottom-margin"> Claims settled</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About_Hitech;
