import React from "react";
import "./Other_card.css";
import Other_Services_main_card from "../../../../Common_component/Other_Services_main_card/Other_Services_main_card";
import { Link } from "react-router-dom";

function Other_card() {
  return (
    <>
      <section className="Other_card">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="other_card_holder">
                <Link to={"/commercial-vehicles-insurance"}>
                  <Other_Services_main_card
                    cardImg={
                      "/assets/image/Services/Others/Other_main_card/Other-img-1.png"
                    }
                    cardText={"Commercial Vehicles Insurance"}
                  />
                </Link>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="other_card_holder">
                <Link to={"/business-insurance"}>
                  <Other_Services_main_card
                    cardImg={
                      "/assets/image/Services/Others/Other_main_card/Other-img-2.png"
                    }
                    cardText={"Business Insurance"}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Other_card;
