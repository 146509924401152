import React from "react";
import "./Business_Lottie.css";
import Lottie from "react-lottie";
import * as animationData from "./Business_Lottie.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";

const Business_Lottie = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <section className="Business_Lottie">
      <div className="Business_Lottie_icon_holder">
        <div className="play-btn-holder">
          <FontAwesomeIcon icon={faCirclePlay} />
        </div>
        <Lottie className="Business_Lottie_icon" options={defaultOptions} />
      </div>
    </section>
  );
};

export default Business_Lottie;
