import React, { useState, useEffect, useContext } from "react";
import "./Get_Quote_Modal.css";
import { Form, Modal } from "react-bootstrap";
import Common_btn_holder from "../Common_btn/Common_btn_holder";
import Bottom_Successfull_Modal from "../Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { postInsurance } from "../../../utils/apis/common/Common";

function Get_Quote_Modal(props) {
  // State to track the visibility of the success modal
  const [successModalShow, setSuccessModalShow] = React.useState(false);

  // Function to handle form submission
  const handleFormSubmit = () => {
    // Hide the current modal
    props.onHide();
    // Show the success modal
    setSuccessModalShow(true);
  };

  console.log(props.insuranceType,"props.insuranceType props.insuranceType props.insuranceType");
  

  const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();

  const onSubmit = async (data) => {
    console.log(data, "data data");
    const formattedData = {

      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      message: data.message,
      contact_number: data.contact_number,
      type: props.insuranceType,

    };
    const res = await postInsurance(formattedData)
    if (res?.success) {
      handleFormSubmit();
      setTimeout(() => {
        setSuccessModalShow(false);
        reset();
      }, 3000);

    } else {
      // if (res?.message?.email) {
      //   setError("email", { message: res?.message?.email });
      // }
      // if (res?.message?.contact_no) {
      //   setError("contact_no", { message: res?.message?.contact_no });
      // }
    }
  }

  return (
    <>
      <section className="Get_Quote_Modal_holder">
        <Modal
          {...props}
          className="Get_Quote_Modal all_modal"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="Inquiry-form-holder">
              <div className="form-heading-holder">
                <h3>Get Quote</h3>
              </div>
              <div className="Inquiry-main-form">
                <Form className="form-holder">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                      <Form.Group controlId="firstName">
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Enter First Name"
                          {...register("first_name", {
                            required: "First Name is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.first_name,
                          })}
                          onKeyDown={(event) => {
                            if (!/[A-Z-a-z ]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.first_name && <div className="invalid-feedback">{errors.first_name.message}</div>}
                      </Form.Group>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                      <Form.Group controlId="lastName">
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Enter Last Name"
                          {...register("last_name", {
                            required: "Last Name is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.last_name,
                          })}
                          onKeyDown={(event) => {
                            if (!/[A-Z-a-z ]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.last_name && <div className="invalid-feedback">{errors.last_name.message}</div>}
                      </Form.Group>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                      <Form.Group controlId="mobileNumber">
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.contact_number,
                            "is-valid": getValues("contact_number"),
                          })}
                          type="text"
                          name="contact_number"
                          placeholder="Enter Mobile Number"
                          {...register("contact_number", {
                            required: "Contact No is required",
                            minLength: {
                              value: 10,
                              message:
                                "Number should be at least 10 characters",
                            },
                          })}
                          onKeyDown={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              if (event.key !== "Backspace") {
                                event.preventDefault();
                              }
                            }
                          }}
                          maxLength={10}
                        />
                        {errors?.contact_number && (
                          <sup className="text-danger">
                            {errors?.contact_number?.message}
                          </sup>
                        )}
                      </Form.Group>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                      <Form.Group controlId="email">
                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="E-mail Address"
                          {...register("email", {
                            required: "Email Id required",
                            pattern: {
                              value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
                              message: "Email Id Invalid",
                            },
                          })}
                          className={classNames("", { "is-invalid": errors?.email })}
                        />
                        {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                      </Form.Group>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-field-holder">
                      <Form.Group controlId="message">
                        <Form.Control
                          as="textarea"
                          name="name"
                          rows={1}
                          placeholder="Type From Here.."
                          {...register("message", {
                            required: "message is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.message,
                          })}

                        />
                        {errors.message && <div className="invalid-feedback">{errors.message.message}</div>}
                      </Form.Group>
                    </div>
                  </div>
                </Form>

                {/* Submit button to trigger modal transition */}
                <Common_btn_holder
                 onClick={handleSubmit(onSubmit)}
                  // onClick={handleFormSubmit}
                  btnText={"Submit"}
                />
              </div>
            </div>
          </Modal.Body>

          <div className="close-btn-holder" onClick={props.onHide}>
            <FontAwesomeIcon className="close-btn" icon={faXmark} />
          </div>
        </Modal>
      </section>

      {/* Bottom Success Modal */}
      <Bottom_Successfull_Modal
        show={successModalShow}
        onHide={() => setSuccessModalShow(false)}
        text={"Quote Generated Successfully!"}
      />
    </>
  );
}

export default Get_Quote_Modal;
