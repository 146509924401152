import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// import toast, { Toaster } from "react-hot-toast";

import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "./api";

import { Per_Page_Dropdown, Select2Data, RegxExpression } from "./common";


export const Context = createContext();

const AppContext = ({ children }) => {
  // const IMG_URL = "http://192.168.14.132:3030";
  // const IMG_URL = "http://192.168.14.116:3030";

  // const IMG_URL = "http://127.0.0.1:3030";
  const IMG_URL = " http://hitechbackend.profcymabackend.com/";
  // const IMG_URL = " http://192.168.16.53/hitech_backend/";

  const navigate = useNavigate();

 
  return (
    <Context.Provider
      value={{
        IMG_URL,
        getData,
        postData,
        putData,
        deleteData,
      
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
