import React from "react";
import "./How_to_Buy_card.css";

function How_to_Buy_card({ cardHeading, content, cardNumber }) {
  return (
    <>
      <section className="How_to_Buy_card_container">
        <div className="How_to_Buy_card">
          <div className="text-holder">
            <h3>{cardHeading}</h3>

            <p className="content-holder">{content}</p>
          </div>
        </div>
        <div className="number-circle-holder">
          <div className="card-number-holder">
            <h3>{cardNumber}</h3>
          </div>
        </div>
      </section>
    </>
  );
}

export default How_to_Buy_card;
