import React from "react";
import "./Contact_Us.css";
import Common_banner from "../Common_component/Common_banner/Common_banner";
import Get_in_touch from "./Get_in_touch/Get_in_touch";

function Contact_Us() {
  return (
    <>
      <div className="Contact_Us">
        <Common_banner
          imgSrc={"/assets/image/Contact_Us/Contact-us-banner.png"}
          mainHeading={"Contact Us"}
          heading={"We're Here to Help"}
        />

        <Get_in_touch />
      </div>
    </>
  );
}

export default Contact_Us;
