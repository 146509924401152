import React from "react";
import Health_Wheeler_Insurance_Banner from "./Health_Wheeler_Insurance_Banner/Health_Wheeler_Insurance_Banner";
import Health_Insurance_Our_Top_Plan from "./Health_Insurance_Our_Top_Plan/Health_Insurance_Our_Top_Plan";
import Health_Insurance_About_us from "./Health_Insurance_About_us/Health_Insurance_About_us";
import Why_Need_Health_Insurance from "./Why_Need_Health_Insurance/Why_Need_Health_Insurance";
import Health_Insurance_How_to_Buy from "./Health_Insurance_How_to_Buy/Health_Insurance_How_to_Buy";

function Health_Insurance() {
  return (
    <>
      <Health_Wheeler_Insurance_Banner
        imgSrc={
          "/assets/image/Services/Wheeler_Service_Banner/Heath-banner.png"
        }
        formText={"Health Insurance"}
        bannerText={"Health Insurance"}
      />

      <Health_Insurance_About_us aboutHeading={"About Heath Insurance"} />

      <Why_Need_Health_Insurance />

      <Health_Insurance_Our_Top_Plan />

      <Health_Insurance_How_to_Buy howToBuyPlanHeading={"How to buy a Health insurance plan"} />
    </>
  );
}

export default Health_Insurance;
