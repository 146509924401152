import React from "react";
import "./Videos_Come_Life.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faCircle } from "@fortawesome/free-solid-svg-icons";
import Business_Lottie from "../../Animation/Business_Lottie/Business_Lottie";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";

function Videos_Come_Life({ IMG_URL, dataVideo }) {
  // Array to hold the video card data
  const videoCardsData = [
    {
      imageSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Videos_Come_Life/Videos_Life-img-1.png",
      description: "Understanding Auto Insurance: A Comprehensive Guide",
      author: "By TATA AIG",
      date: "12 Jan 2024",
    },
    {
      imageSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Videos_Come_Life/Videos_Life-img-2.png",
      description: "Understanding Health Insurance Basics",
      author: "By Max Bupa",
      date: "22 Feb 2024",
    },
    {
      imageSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Videos_Come_Life/Videos_Life-img-3.png",
      description: "All About Car Insurance Policies",
      author: "By HDFC ERGO",
      date: "5 Mar 2024",
    },
  ];

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return an empty string if no date is provided

    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",     // Day with leading zeros (01, 02, 12, etc.)
      month: "short",     // Abbreviated month (Jan, Feb, etc.)
      year: "numeric",    // Full year (2024)
    });
  };

  const handleRedirect = (video) => {
    // Redirect to the YouTube video URL
    window.open(video, '_blank'); // Opens in a new tab
  };
  return (
    <>
      <section className="Videos_Come_Life_holder">
        <div className="container">
          <div className="Videos_Come_Life">
            <div className="Videos_Come_Life_content">
              <div className="row">
                <div className="col-lg-3 col-md-12">
                  <div className="section-heading-holder">
                    <h3>Where Videos Come to Life</h3>
                    <p>Dive Into Our Video World</p>
                  </div>

                  <div className="Videos_Life_img_holder">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/image/Homepage/Videos_Come_Life/Videos-Come-Life-img.png"
                      }
                      className="Videos_Life_img"
                      alt="Videos_Life_img"
                    />
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="row Videos_Life_desktop_view">
                    {dataVideo?.map((video, index) => (
                      <div className="col-md-12 col-sm-6 col-12" key={index}>
                        <div className="Videos_Come_Life_card_holder">
                          <div className="row  me-0 ms-0">
                            <div className="col-md-4 ps-0">
                              <div className="member_img_holder" onClick={() => handleRedirect(video?.iframe)} style={{ cursor: 'pointer' }}>
                                {/* <img
                                  src={video.imageSrc}
                                  className="member"
                                  alt="member"
                                /> */}
                                <iframe
                                  width="100"
                                  height="100"
                                  src={video?.ifream}
                                  title="YouTube video player"
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                ></iframe>
                                {/* <iframe
                                  width="100"
                                  height="100"
                                  src={video?.iframe} // Ensure this contains the embed URL
                                  title="YouTube video player"
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                  // style={{ pointerEvents: 'none' }} // Prevent click events on the iframe itself
                                ></iframe> */}
                              </div>
                            </div>

                            <div className="col-md-8 schedule-text">
                              <div className="text-holder">
                                <p>{video.title}</p>
                              </div>

                              <div className="schedule-holder">
                                <div className="name-holder">
                                  <p>{video.name}</p>
                                </div>
                                <div className="date-holder">
                                  <FontAwesomeIcon
                                    className="dot-icon"
                                    icon={faCircle}
                                  />
                                  <FontAwesomeIcon
                                    className="icon-holder"
                                    icon={faCalendarDays}
                                  />
                                  <p>{formatDate(video?.created_at)}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row Videos_Life_mobile_view">
                    <Swiper
                      spaceBetween={30}
                      slidesPerView={4}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => console.log(swiper)}
                      className="Insurance_Services-slider Videos_Come_Life_slider"
                      modules={[Pagination, Autoplay]}
                      loop={true}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      pagination={{
                        dynamicBullets: true,
                        clickable: true
                      }}
                      breakpoints={{
                        // when window width is >= 320px
                        320: {
                          slidesPerView: 1,
                          spaceBetween: 10,
                        },
                        // when window width is >= 480px
                        480: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        // when window width is >= 768px
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 30,
                        },

                        // when window width is >= 992px
                        992: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },
                        // when window width is >= 1024px
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },

                        // when window width is >= 1200px
                        1299: {
                          slidesPerView: 4,
                          spaceBetween: 20,
                        },
                      }}
                    >
                      {videoCardsData.map((video, index) => (
                        <SwiperSlide>
                          <div className="Videos_Come_Life_card_holder">
                            <div className="row  me-0 ms-0">
                              <div className="col-md-4 p-0">
                                <div className="member_img_holder">
                                  <img
                                    src={video.imageSrc}
                                    className="member"
                                    alt="member"
                                  />
                                </div>
                              </div>

                              <div className="col-md-8 schedule-text">
                                <div className="text-holder">
                                  <p>{video.description}</p>
                                </div>

                                <div className="schedule-holder">
                                  <div className="name-holder">
                                    <p>{video.author}</p>
                                  </div>
                                  <div className="date-holder">
                                    <FontAwesomeIcon
                                      className="dot-icon"
                                      icon={faCircle}
                                    />
                                    <FontAwesomeIcon
                                      className="icon-holder"
                                      icon={faCalendarDays}
                                    />
                                    <p>{video.date}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="businessman-content-holder">
                    <div className="businessman_img_holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/image/Homepage/Videos_Come_Life/businessman-img.png"
                        }
                        className="businessman_img"
                        alt="businessman_img"
                      />
                    </div>

                    <div className="overlay-holder"></div>
                    <div className="businessman-text-holder">
                      <p>Understanding Auto Insurance A Comprehensive Guide</p>
                    </div>

                    <div className="lottie-holder">
                      <Business_Lottie />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Videos_Come_Life;
