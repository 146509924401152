import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./Blog_inner_Banner.css"

function Blog_inner_Banner({data,IMG_URL}) {
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return an empty string if no date is provided

    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",     // Day with leading zeros (01, 02, 12, etc.)
      month: "short",     // Abbreviated month (Jan, Feb, etc.)
      year: "numeric",    // Full year (2024)
    });
  };
  return (
    <>
      <section className="Blog_inner_Banner">
        <div className="container">
          <div className="card-img-holder">
            <div className="img-holder">
              <img
                src={
                  IMG_URL +
                 data?.image
                }
                className="Blog_card_img"
                alt="Blog_card_img"
              />
            </div>
            <div className="overlay-holder"></div>

            <div className="text-holder">
            <h3>{data?.title}</h3>
              <p>
                <FontAwesomeIcon className="text-icon" icon={faCalendar} /> {formatDate(data?.created_at)}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog_inner_Banner;
