import React from "react";
import "./Help_Us.css";

function Help_Us() {
  return (
    <>
      <section className="Help_Us">
        <div className="container">
          <div className="Help_Us_holder">
            <div className="row justify-content-between">
              <div className="col-lg-5 col-md-5">
                <div className="Help_Us_img_holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/image/About_Us/Help_Us/Help-Us.png"
                    }
                    className="Help_Us_img"
                    alt="Help_Us_img"
                  />
                </div>
              </div>

              <div className="col-md-7">
                <div className="content-holder">
                  <div>
                    <h3>Help Us to Serve you better</h3>
                    <p>
                      At Hitech Solutions, your satisfaction is our top
                      priority. We are committed to providing you with the best
                      possible service and support. To help us improve and
                      tailor our offerings to your needs, we invite you to share
                      your feedback and suggestions. Whether it’s about our
                      products, services, or customer support, your insights are
                      invaluable in helping us serve you better. Together, we
                      can create a more seamless and satisfying experience for
                      everyone. Thank you for choosing Hitech Solutions!
                    </p>
                    <p className="Send-Feedback-text">Send Feedback</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Help_Us;
