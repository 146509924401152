import React from "react";
import Four_Wheeler_About_Us from "./Four_Wheeler_About_Us/Four_Wheeler_About_Us";
import Types_of_Four_Wheeler from "./Types_of_Four_Wheeler/Types_of_Four_Wheeler";
import Third_party_Insurance from "./Third_party_Insurance/Third_party_Insurance";
import How_to_Buy from "./How_to_Buy/How_to_Buy";
import Four_Wheeler_Insurance_Banner from "./Four_Wheeler_Insurance_Banner/Four_Wheeler_Insurance_Banner";

function Four_Wheeler_Insurance() {
  return (
    <>
      <Four_Wheeler_Insurance_Banner
        imgSrc={
          "/assets/image/Services/Wheeler_Service_Banner/Four-Wheeler-Service-Banner-img-1.png"
        }
        formText={"4 Wheeler Insurance"}
        bannerText={"4 Wheeler Insurance"}
      />

      <Four_Wheeler_About_Us aboutHeading={"About 4 Wheeler Insurance"} />

      <Types_of_Four_Wheeler typeHeading={"Types of Four-Wheeler Insurance"}/>

      <Third_party_Insurance />

      <How_to_Buy howToBuyPlanHeading={"How to buy a 4-wheeler insurance plan"}/>
    </>
  );
}

export default Four_Wheeler_Insurance;
