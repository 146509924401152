import React from "react";
import Home_Insurance_About_us from "../Home_Insurance/Home_Insurance_About_us/Home_Insurance_About_us";
import Travel_Wheeler_Insurance_Banner from "./Travel_Wheeler_Insurance_Banner/Travel_Wheeler_Insurance_Banner";
import Travel_Insurance_Our_Top_Plan from "./Travel_Insurance_Our_Top_Plan/Travel_Insurance_Our_Top_Plan";
import Travel_Insurance_How_to_Buy from "./Travel_Insurance_How_to_Buy/Travel_Insurance_How_to_Buy";
import Why_Need_Travel_Insurance from "./Why_Need_Travel_Insurance/Why_Need_Travel_Insurance";

function Travel_Insurance_About_Us() {
  return (
    <>
      <Travel_Wheeler_Insurance_Banner
        imgSrc={
          "/assets/image/Services/Wheeler_Service_Banner/Heath-banner.png"
        }
        formText={"Travel Insurance"}
        bannerText={"Travel Insurance"}
      />

      <Home_Insurance_About_us aboutHeading={"About Travel Insurance"} />

      <Why_Need_Travel_Insurance />

      <Travel_Insurance_Our_Top_Plan />

      <Travel_Insurance_How_to_Buy howToBuyPlanHeading={"How to buy a Travel insurance plan"}/>
    </>
  );
}

export default Travel_Insurance_About_Us;
