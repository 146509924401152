import React from "react";
import Our_Top_Plan_card from "../../../Common_component/Our_Top_Plan_card/Our_Top_Plan_card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "swiper/css/pagination";
import "swiper/css";

function Travel_Insurance_Our_Top_Plan() {
  const planBenefits1 = [
    "Medical and Health Coverage",
    "Emergency Medical Evacuation and Repatriation",
    "Trip Cancellation or Interruption",
    "Lost, Stolen, or Delayed Baggage",
    "Travel Delay and Missed Connections",
    "Personal Liability Coverage",
    "Accidental Death and Dismemberment",
    "Adventure Sports Coverage",
    "Rental Car Damage Coverage",
    "Political or Natural Disaster Evacuation",
  ];

  const planBenefits2 = [
    "Medical and Health Coverage",
    "Emergency Medical Evacuation and Repatriation",
    "Trip Cancellation or Interruption",
    "Lost, Stolen, or Delayed Baggage",
    "Travel Delay and Missed Connections",
    "Personal Liability Coverage",
    "Accidental Death and Dismemberment",
    "Adventure Sports Coverage",
    "Rental Car Damage Coverage",
    "Political or Natural Disaster Evacuation"
  ];

  const planBenefits3 = [
    "Medical and Health Coverage",
    "Emergency Medical Evacuation and Repatriation",
    "Trip Cancellation or Interruption",
    "Lost, Stolen, or Delayed Baggage",
    "Travel Delay and Missed Connections",
    "Personal Liability Coverage",
    "Accidental Death and Dismemberment",
    "Adventure Sports Coverage",
    "Rental Car Damage Coverage",
    "Political or Natural Disaster Evacuation"
  ];
  

  const planBenefits4 = [
    "Medical and Health Coverage",
    "Emergency Medical Evacuation and Repatriation",
    "Trip Cancellation or Interruption",
    "Lost, Stolen, or Delayed Baggage",
    "Travel Delay and Missed Connections",
    "Personal Liability Coverage",
    "Accidental Death and Dismemberment",
    "Adventure Sports Coverage",
    "Rental Car Damage Coverage",
    "Political or Natural Disaster Evacuation"
  ];
  

  const planBenefits5 = [
    "Medical and Health Coverage",
    "Emergency Medical Evacuation and Repatriation",
    "Trip Cancellation or Interruption",
    "Lost, Stolen, or Delayed Baggage",
    "Travel Delay and Missed Connections",
    "Personal Liability Coverage",
    "Accidental Death and Dismemberment",
    "Adventure Sports Coverage",
    "Rental Car Damage Coverage",
    "Political or Natural Disaster Evacuation"
  ];
  

  return (
    <>
      <section className="Our_Top_Plan">
        <div className="container">
          <div className="heading-holder">
            <div className="Our_Top_Plan_heading_holder">
              <h3>Our Top Plan</h3>
            </div>
          </div>

          <div className="Our_Top_Plan_section">
            <Swiper
              spaceBetween={50}
              slidesPerView={4}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              className="Insurance_Journey-slider Our_Top_Plan_slider"
              modules={[Pagination]}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                480: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },

                575: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1299: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
            >
              <SwiperSlide>
                <div className="Our_Top_Plan_card_holder">
                  <Our_Top_Plan_card
                    cardColor={"#fff9eb"}
                    locationText={"USA"}
                    companyText={"TATA AIG"}
                    pricePremiumText={"$1500 /mo"}
                    fromPrice={"$10,000"}
                    toPrice={"$500,000"}
                    planBenefits={planBenefits1}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Our_Top_Plan_card_holder">
                  <Our_Top_Plan_card
                    cardColor={"#FFF1CE "}
                    locationText={"canada"}
                    companyText={"TATA AIG"}
                    pricePremiumText={"$3000 /mo"}
                    fromPrice={"$100,000"}
                    toPrice={"$500,000"}
                    planBenefits={planBenefits2}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Our_Top_Plan_card_holder">
                  <Our_Top_Plan_card
                    cardColor={"#FCE8B4 "}
                    locationText={"UK"}
                    companyText={"TATA AIG"}
                    pricePremiumText={"£1200 /mo"}
                    fromPrice={" £100,000"}
                    toPrice={" £250,000"}
                    planBenefits={planBenefits3}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Our_Top_Plan_card_holder">
                  <Our_Top_Plan_card
                    cardColor={"#FFE298"}
                    locationText={"France/Germany"}
                    companyText={"TATA AIG"}
                    pricePremiumText={"€800 /mo"}
                    fromPrice={"€30,000"}
                    toPrice={"€100,000"}
                    planBenefits={planBenefits4}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Our_Top_Plan_card_holder">
                  <Our_Top_Plan_card
                    cardColor={"#FFD774"}
                    locationText={"Australia"}
                    companyText={"TATA AIG"}
                    pricePremiumText={"$1200 /mo"}
                    fromPrice={"$50,000"}
                    toPrice={"$300,000"}

                    planBenefits={planBenefits5}
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default Travel_Insurance_Our_Top_Plan;
