import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const postBlogComment = async (data) => {
  try {
    return await postData(`blog-comment`, data);
  } catch (error) {
    console.log(error);
  }
};
export const postInsurance = async (data) => {
  try {
    return await postData(`inquiry`, data);
  } catch (error) {
    console.log(error);
  }
};
export const postContactUs = async (data) => {
  try {
    return await postData(`contactusform`, data);
  } catch (error) {
    console.log(error);
  }
};


export const getAllProject = async () => {
  try {
    return await getData(`getprojects`);
  } catch (error) {
    console.log(error);
  }
}

export const getAllProudtoPartner = async () => {
  try {
    return await getData(`get-proud-to-partner`);
  } catch (error) {
    console.log(error);
  }
}

export const getAllDataHappyCustomerData = async () => {
  try {
    return await getData(`get-happy-clients`);
  } catch (error) {
    console.log(error);
  }
}


export const getBlogs = async () => {
  try {
    return await getData(`get-blogs`);
  } catch (error) {
    console.log(error);
  }
}
export const getVideos = async () => {
  try {
    return await getData(`get-video-come-to-life`);
  } catch (error) {
    console.log(error);
  }
}

export const getInnerBlogs = async (id) => {
  try {
    return await getData(`get-blogs/${id}`);
  } catch (error) {
    console.log(error);
  }
}

