import React from 'react'
import "./Common_btn_holder.css"
import { Button } from 'react-bootstrap'

function Common_btn_holder({onClick, btnText}) {
  return (
    <>
        <div className="form-btn-holder">
          <Button
            className="Get-Quote-btn"
            type="button"
            onClick={onClick}
          >
           {btnText}
          </Button>
        </div>
    </>
  )
}

export default Common_btn_holder