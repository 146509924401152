import React from "react";
import "./Other_Services_main_card.css";
import { Link } from "react-router-dom";

function Other_Services_main_card({cardText, onClick, cardImg}) {
  return (
    <>
      
        <section className="Other_Services_main_card" onClick={onClick}>
          <div className="img-holder">
            <img
              src={
                process.env.PUBLIC_URL +
               cardImg
              }
              className="Other-img"
              alt="Other-img"
            />
          </div>

          <div className="overlay-holder"></div>

          <div className="text-holder">
            <p>{cardText}</p>
          </div>
        </section>
     
    </>
  );
}

export default Other_Services_main_card;
