import React from "react";
import "./App.css";
import "./index.css";

import { Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import About_Us from "./components/About_Us/About_Us";
import Contact_Us from "./components/Contact_Us/Contact_Us";
import Terms_Conditions from "./components/Terms_Conditions/Terms_Conditions";
import Privacy_Policy from "./components/Privacy_Policy/Privacy_Policy";
import Blog_Main from "./components/Blog/Blog_Main/Blog_Main";
import Blog_inner from "./components/Blog/Blog_inner/Blog_inner";
import Four_Wheeler_Insurance from "./components/Our_Services/Four_Wheeler_Insurance/Four_Wheeler_Insurance";
import Two_Wheeler_Insurance from "./components/Our_Services/Two_Wheeler_Insurance/Two_Wheeler_Insurance";
import Health_Insurance from "./components/Our_Services/Health_Insurance/Health_Insurance";
import Other_Service from "./components/Our_Services/Other_Service/Other_Service";
import Travel_Insurance from "./components/Our_Services/Travel_Insurance/Travel_Insurance";
import Home_Insurance from "./components/Our_Services/Home_Insurance/Home_Insurance";
import Testimonials from "./components/Testimonials/Testimonials";
import ScrollToTop from "./components/ScrollTotop/ScrollTotop";
import Call_Icon from "./components/Common_component/Call_Icon/Call_Icon";
import Commercial_Vehicles_Insurance from "./components/Our_Services/Other_Service/Other_inner/Commercial_Vehicles_Insurance/Commercial_Vehicles_Insurance";
import Business_Insurance from "./components/Our_Services/Other_Service/Other_inner/Business_Insurance/Business_Insurance";
import AppContext from "./utils/context";
function App() {
  return (
    <>
      <ScrollToTop />
      <div className="website_design-holder">
        <Header />
       <AppContext> 
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About_Us />} />
          <Route path="/blogs" element={<Blog_Main />} />
          <Route path="/blog-inner/:id" element={<Blog_inner />} />
          <Route path="/contact-us" element={<Contact_Us />} />
          <Route path="/terms-conditions" element={<Terms_Conditions />} />
          <Route path="/privacy-policy" element={<Privacy_Policy />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route
            path="/four-wheeler-insurance"
            element={<Four_Wheeler_Insurance />}
          />
          <Route
            path="/two-wheeler-insurance"
            element={<Two_Wheeler_Insurance />}
          />
          <Route path="/other" element={<Other_Service />} />
          <Route path="/commercial-vehicles-insurance" element={<Commercial_Vehicles_Insurance />} />
          <Route path="/business-insurance" element={<Business_Insurance />} />
          <Route path="/health-insurance" element={<Health_Insurance />} />
          <Route path="/travel-insurance" element={<Travel_Insurance />} />
          <Route path="/home-insurance" element={<Home_Insurance />} />
        </Routes>
       </AppContext> 
        <Footer />
        <div className="common-icon-holder">
          <Call_Icon />
        </div>
      </div>
    </>
  );
}

export default App;
