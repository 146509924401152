import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./Comments_card_holder.css";

function Comments_card_holder({ data }) {
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return an empty string if no date is provided

    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",     // Day with leading zeros (01, 02, 12, etc.)
      month: "short",     // Abbreviated month (Jan, Feb, etc.)
      year: "numeric",    // Full year (2024)
    });
  };
  return (
    <>
      <section className="Comments_card_holder">
        {data?.map((val)=>(
        <div className="Comments_card-holder">
          <div className="commenter-holder">
         
            <div className="row" >
              <div className="col-md-2 col-sm-2">
                <div className="main-img-holder commenter-profile">
                  <div className="profile-img-holder">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/image/Blog/Commenter_img/Commenter-img-dummy-1.png"}
                      className="profile-img"
                      alt="profile-img"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-10 col-sm-10">
                <div className="comment-info-holder ">
                  <div className="comment-info-text commenter-text-time">
                    <div className="name-text">
                      <h3>
                       
                        {val?.first_name} {val?.last_name}
                      </h3>
                    </div>

                    <div className="time-text">
                      <p>
                        <FontAwesomeIcon className="circle-icon" icon={faCircle} />{" "}
                       
                        {formatDate(val?.created_at)}
                      </p>
                    </div>
                  </div>

                  <div className="comment-text-holder">
                   
                     <p>{val?.message}</p> 
                  </div>
                </div>

                {/* <hr/> */}
              </div>
            </div>

            {/* ))} */}

          </div>


          {/* <div className="commenter-holder">
            <div className="row">
              <div className="col-md-2 col-sm-2">
                <div className="main-img-holder reply-profile">
                  <div className="profile-img-holder">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "   /assets/image/Blog/Commenter_img/Commenter-img-dummy-1.png"
                      }
                      className="profile-img"
                      alt="profile-img"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-10 col-sm-10">
                <div className="comment-info-holder">
                  <div className="comment-text-holder">
                    <p>
                      Thanks for the tips on saving money! I didn't know
                      bundling policies could help. Are there any specific
                      companies that offer the best bundle discounts?"
                    </p>
                  </div>

                  <div className="comment-info-text">
                    <div className="name-text">
                      <h3>Rahul Mahajan</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="commenter-holder">
            <div className="row">
              <div className="col-md-2 col-sm-2">
                <div className="main-img-holder reply-profile">
                  <div className="profile-img-holder">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "   /assets/image/Blog/Commenter_img/Commenter-img-dummy-1.png"
                      }
                      className="profile-img"
                      alt="profile-img"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-10 col-sm-10">
                <div className="comment-info-holder">
                  <div className="comment-text-holder">
                    <p>
                      Thanks for the tips on saving money! I didn't know
                      bundling policies could help. Are there any specific
                      companies that offer the best bundle discounts?"
                    </p>
                  </div>

                  <div className="comment-info-text">
                    <div className="name-text">
                      <h3>Rahul Mahajan</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>))}


        {/* <div className="Comments_card-holder">
          <div className="commenter-holder">

            <div className="row" >
              <div className="col-md-2 col-sm-2">
                <div className="main-img-holder commenter-profile">
                  <div className="profile-img-holder">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/image/Blog/Commenter_img/Commenter-img-dummy-1.png"}
                      className="profile-img"
                      alt="profile-img"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-10 col-sm-10">
                <div className="comment-info-holder ">
                  <div className="comment-info-text commenter-text-time">
                    <div className="name-text">
                      <h3>
                        Rahul Mahajan
                       
                      </h3>
                    </div>

                    <div className="time-text">
                      <p>
                        <FontAwesomeIcon className="circle-icon" icon={faCircle} />{" "}
                        12 jun 2024
                        
                      </p>
                    </div>
                  </div>

                  <div className="comment-text-holder">
                    <p>jtt jggjhj jjkjkgjhk</p>
                   
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div> */}


        {/* <div className="Comments_card-holder">
          <div className="commenter-holder">

            <div className="row" >
              <div className="col-md-2 col-sm-2">
                <div className="main-img-holder commenter-profile">
                  <div className="profile-img-holder">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/image/Blog/Commenter_img/Commenter-img-dummy-1.png"}
                      className="profile-img"
                      alt="profile-img"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-10 col-sm-10">
                <div className="comment-info-holder ">
                  <div className="comment-info-text commenter-text-time">
                    <div className="name-text">
                      <h3>
                        Rahul Mahajan
                      
                      </h3>
                    </div>

                    <div className="time-text">
                      <p>
                        <FontAwesomeIcon className="circle-icon" icon={faCircle} />{" "}
                        12 jun 2024
                      
                      </p>
                    </div>
                  </div>

                  <div className="comment-text-holder">
                    <p>jtt jggjhj jjkjkgjhk</p>
                   
                  </div>
                </div>


              </div>
            </div>



          </div>


          <div className="commenter-holder">
            <div className="row">
              <div className="col-md-2 col-sm-2">
                <div className="main-img-holder reply-profile">
                  <div className="profile-img-holder">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "   /assets/image/Blog/Commenter_img/Commenter-img-dummy-1.png"
                      }
                      className="profile-img"
                      alt="profile-img"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-10 col-sm-10">
                <div className="comment-info-holder">
                  <div className="comment-text-holder">
                    <p>
                      Thanks for the tips on saving money! I didn't know
                      bundling policies could help. Are there any specific
                      companies that offer the best bundle discounts?"
                    </p>
                  </div>

                  <div className="comment-info-text">
                    <div className="name-text">
                      <h3>Rahul Mahajan</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="commenter-holder">
            <div className="row">
              <div className="col-md-2 col-sm-2">
                <div className="main-img-holder reply-profile">
                  <div className="profile-img-holder">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "   /assets/image/Blog/Commenter_img/Commenter-img-dummy-1.png"
                      }
                      className="profile-img"
                      alt="profile-img"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-10 col-sm-10">
                <div className="comment-info-holder">
                  <div className="comment-text-holder">
                    <p>
                      Thanks for the tips on saving money! I didn't know
                      bundling policies could help. Are there any specific
                      companies that offer the best bundle discounts?"
                    </p>
                  </div>

                  <div className="comment-info-text">
                    <div className="name-text">
                      <h3>Rahul Mahajan</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
}

export default Comments_card_holder;
