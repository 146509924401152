import React, { useState, useEffect, useContext } from "react";
import "./Get_in_touch.css";
import Bottom_Successfull_Modal from "../../Common_component/Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import Common_btn_holder from "../../Common_component/Common_btn/Common_btn_holder";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapLocation,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { postContactUs } from "../../../utils/apis/common/Common";
function Get_in_touch() {
  const [modalShow, setModalShow] = React.useState(false);
  const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();

  const onSubmit = async (data) => {
    console.log(data, "data data");
    const formattedData = {

      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      message: data.message,
      contact_number: data.contact_number,


    };
    const res = await postContactUs(formattedData)
    if (res?.success) {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        reset();
      }, 3000);

    } else {
      // if (res?.message?.email) {
      //   setError("email", { message: res?.message?.email });
      // }
      // if (res?.message?.contact_no) {
      //   setError("contact_no", { message: res?.message?.contact_no });
      // }
    }
  }
  return (
    <>
      <section className="Get_in_touch">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-11">
              <div className="Get_in_touch_Form_holder">
                <div className="row">
                  <div className="col-md-5">
                    <div className="Get_in_touch_holder">
                      <div className="form-heading-holder">
                        <h3>Get in touch</h3>
                      </div>

                      <div className="contact-info-holder">
                        <div className="info-text-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-3 col-3 icon-holder">
                              <div className="">
                                <FontAwesomeIcon
                                  className="contact-icon"
                                  icon={faPhoneVolume}
                                />
                              </div>
                            </div>
                            <div className="col-md-9 col-9">
                              <p>+91 9845348745</p>
                            </div>
                          </div>
                        </div>

                        <div className="info-text-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-3 col-3 icon-holder">
                              <div className="">
                                <FontAwesomeIcon
                                  className="contact-icon"
                                  icon={faEnvelope}
                                />
                              </div>
                            </div>
                            <div className="col-md-9 col-9">
                              <p>hitechsolutions2007@gmail.com</p>
                            </div>
                          </div>
                        </div>

                        <div className="info-text-holder">
                          <div className="row me-0 ms-0">
                            <div className="col-md-3 col-3 icon-holder">
                              <div className="">
                                <FontAwesomeIcon
                                  className="contact-icon"
                                  icon={faMapLocation}
                                />
                              </div>
                            </div>
                            <div className="col-md-9 col-9">
                              <p>
                                301, 2nd Floor, 3rd Cross, 6th Main Rd, HRBR Layout,
                                Kalyan Nagar, Bengaluru, Karnataka 560043
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-7">
                    <div className="Send_us_Message_holder">
                      <div className="form-heading-holder">
                        <h3>Send us a Message</h3>
                      </div>
                      <div className="Inquiry-main-form">
                        <Form className="form-holder">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Enter First Name"
                                  {...register("first_name", {
                                    required: "First Name is required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.first_name,
                                  })}
                                  onKeyDown={(event) => {
                                    if (!/[A-Z-a-z ]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {errors.first_name && <div className="invalid-feedback">{errors.first_name.message}</div>}
                              </Form.Group>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Enter Last Name"
                                  {...register("last_name", {
                                    required: "Last Name is required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.last_name,
                                  })}
                                  onKeyDown={(event) => {
                                    if (!/[A-Z-a-z ]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {errors.last_name && <div className="invalid-feedback">{errors.last_name.message}</div>}
                              </Form.Group>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  className={classNames("", {
                                    "is-invalid": errors?.contact_number,
                                    "is-valid": getValues("contact_number"),
                                  })}
                                  type="text"
                                  name="contact_number"
                                  placeholder="Enter Mobile Number"
                                  {...register("contact_number", {
                                    required: "Contact No is required",
                                    minLength: {
                                      value: 10,
                                      message:
                                        "Number should be at least 10 characters",
                                    },
                                  })}
                                  onKeyDown={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      if (event.key !== "Backspace") {
                                        event.preventDefault();
                                      }
                                    }
                                  }}
                                  maxLength={10}
                                />
                                {errors?.contact_number && (
                                  <sup className="text-danger">
                                    {errors?.contact_number?.message}
                                  </sup>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  name="email"
                                  placeholder="E-mail Address"
                                  {...register("email", {
                                    required: "Email Id required",
                                    pattern: {
                                      value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
                                      message: "Email Id Invalid",
                                    },
                                  })}
                                  className={classNames("", { "is-invalid": errors?.email })}
                                />
                                {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                              </Form.Group>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-field-holder">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  as="textarea"
                                  name="name"
                                  rows={1}
                                  placeholder="Type From Here.."
                                  {...register("message", {
                                    required: "message is required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.message,
                                  })}

                                />
                                {errors.message && <div className="invalid-feedback">{errors.message.message}</div>}
                              </Form.Group>
                            </div>
                          </div>
                        </Form>

                        <Common_btn_holder
                          onClick={handleSubmit(onSubmit)}
                          // onClick={() => setModalShow(true)}
                          btnText={"Submit"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="google_map_holder">
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe
                      style={{ height: "100%", width: "100%", border: "0" }}
                      frameBorder="0"
                      src="https://www.google.com/maps/embed/v1/place?q=301,+2nd+Floor,+3rd+Cross,+6th+Main+Rd,+HRBR+Layout,+Kalyan+Nagar,+Bengaluru,+Karnataka+560043&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                      allowFullScreen
                      title="Google Map Location"
                    ></iframe>

                    <a
                      className="google-maps-html"
                      href="https://www.bootstrapskins.com/themes"
                      id="auth-map-data"
                      style={{ color: "#000", textDecoration: "none" }}
                    >
                      premium bootstrap themes
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Bottom_Successfull_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        text={"Comment Posted Successfully!"}
      />
    </>
  );
}

export default Get_in_touch;
