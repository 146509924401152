import React from "react";
import "./About_Us_Content.css"

function About_Us_Content() {
  return (
    <>
      <section className="About_Us_Content">
        <div className="container">
          <div className="text_holder">
            <h4>
              Hitech Solutions: 23 Years of Trusted Insurance Services at Hitech
              Solutions, we have proudly served our customers for over 23 years,
              offering reliable insurance solutions for motor, health, travel,
              and property.
            </h4>

            <p>
              Our strong partnerships with leading insurers like TATA AIG, ICICI
              Lombard, Royal Sundaram, and Go Digit enable us to provide
              personalized insurance plans tailored to your unique needs.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default About_Us_Content;
