import React from "react";
import Other_Service_banner from "../../../../Common_component/Other_Service_banner/Other_Service_banner";
import Breadcrumbs_Holder from "../../../../Common_component/Breadcrumbs_Holder/Breadcrumbs_Holder";
import Commercial_Vehicles_Insurance_Card from "./Commercial_Vehicles_Insurance_Card/Commercial_Vehicles_Insurance_Card";

function Commercial_Vehicles_Insurance() {
  const breadcrumbs = [
    { label: "Other", link: "/other", active: false },
    { label: "Commercial Vehicles Insurance", link: "", active: true },
  ];
  return (
    <>
      <Other_Service_banner bannerText={"Commercial Vehicles Insurance"} />

      <Breadcrumbs_Holder breadcrumbs={breadcrumbs} />

      <Commercial_Vehicles_Insurance_Card/>
    </>
  );
}

export default Commercial_Vehicles_Insurance;
