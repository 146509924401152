import React from "react";
import "./Banner.css";
import { Container } from "react-bootstrap";
import BannerTab from "./BannerTab/BannerTab";

function Banner() {
  return (
    <>
      <section className="home-banner">
        <div className="container-fluid p-0">
          <div className="main-banner-class">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/image/Homepage/Banner/homebanner.png"
              }
              className="banner-img"
              alt="..."
            />
            <div className="main-div-banner">
              <Container>
                <div className="row">
                  <div className="col-xl-6 col-lg-7 col-8">
                    <h5 className="banner-head-title">
                      Your One-Stop Solutions
                    </h5>
                    <p className="inshu-txttt">for All Insurance Needs</p>
                    <div className="we-provide-txtt-holder">
                      <p className="we-provide-txtt">We Provides</p>
                    </div>
                    <div className="tab-div  desktop-view-tab-div">
                      <BannerTab />
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </section>

      <div className="tab-div  mobile-view-tab-div">
        <BannerTab />
      </div>
    </>
  );
}

export default Banner;
