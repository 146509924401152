import React, { useState } from "react";
import "./Commercial_Vehicles_Insurance_Card.css"
import Other_Services_main_card from '../../../../../Common_component/Other_Services_main_card/Other_Services_main_card';
import Get_Quote_Modal from '../../../../../Common_component/Get_Quote_Modal/Get_Quote_Modal';

function Commercial_Vehicles_Insurance_Card(props) {
    const [modalShow, setModalShow] = React.useState(false);
    const [insuranceType, setInsuranceType] = useState('');

  const handleQuoteModal = (type) => {
    console.log(type,'type ttttttttttttttt');
    
    setInsuranceType(type);
    setModalShow(true); // Open Get_Quote_Modal
    // props.onHide(); // Close Other_Insurance_Modal
   
  };
  console.log(insuranceType,"insuranceType insuranceType");
  return (
    <>
        <section className="Other_card Other_Inner_Card">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="other_card_holder">
                <Other_Services_main_card

                  cardImg={
                    "/assets/image/Services/Others/Commercial_inner_card/Car.png"
                  }
                  cardText={"Commercial Car Insurance"}
                  onClick={() => handleQuoteModal("Other-CVI-CCI")}
                  
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="other_card_holder">
                <Other_Services_main_card
                  cardImg={
                    "/assets/image/Services/Others/Commercial_inner_card/Auto.png"
                  }
                  cardText={"Commercial Auto Insurance"}
                  onClick={() => handleQuoteModal("Other-CVI-CAI")}
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="other_card_holder">
                <Other_Services_main_card
                  cardImg={
                    "/assets/image/Services/Others/Commercial_inner_card/Truck.png"
                  }
                  cardText={"Commercial Truck Insurance"}
                  onClick={() => handleQuoteModal("Other-CVI-CTI")}
                  // onClick={() => setModalShow(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Get_Quote_Modal show={modalShow} onHide={() => setModalShow(false)} /> */}
      <Get_Quote_Modal insuranceType={insuranceType} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default Commercial_Vehicles_Insurance_Card