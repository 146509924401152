import React from "react";
import How_to_Buy_card from "../../../Common_component/How_to_Buy_card/How_to_Buy_card";

function Travel_Insurance_How_to_Buy({ howToBuyPlanHeading }) {
  return (
    <>
      <section className="How_to_Buy">
        <div className="container">
          <div className="How_to_Buy-holder">
            <div className="section-heading-holder">
              <h3>{howToBuyPlanHeading}</h3>
            </div>

            <div className="How_to_Buy_card_holder">
              <div className="card_holder">
                <How_to_Buy_card
                  cardNumber={"1"}
                  cardHeading={"Choose Your Destination:"}
                  content={
                    "contact us and let us know about the country you’re traveling to."
                  }
                />
              </div>

              <div className="card_holder">
                <How_to_Buy_card
                  cardNumber={"2"}
                  cardHeading={"Select Your Plan: "}
                  content={
                    "Pick a plan that fits your travel needs and budget."
                  }
                />
              </div>

              <div className="card_holder">
                <How_to_Buy_card
                  cardNumber={"3"}
                  cardHeading={"Enter Your Details:"}
                  content={
                    "Fill out traveller information for an instant quote."
                  }
                />
              </div>

              <div className="card_holder">
                <How_to_Buy_card
                  cardNumber={"4"}
                  cardHeading={"Enjoy Full Coverage:"}
                  content={
                    "Complete the purchase and get covered!"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Travel_Insurance_How_to_Buy;
