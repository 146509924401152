import React from "react";
import "./Home_Blogs.css";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Home_Blogs({ dataBLogs, IMG_URL }) {
  console.log(dataBLogs?.length, "dataBLogs?.length dataBLogs?.length");

  return (
    <>
      <section className="Home_Blogs_holder">
        <div className="container">
          <div className="Home_Blogs">
            <div className="section-heading-holder">
              <h3>Blogs</h3>
              <p>Where Ideas Meet Action</p>
            </div>

            <div className="Home_Blogs">
              <div className="row">
                <div className=" col-md-4">
                  <div className="row">
                    <div className="col-md-12 space-holder">
                      {dataBLogs.length > 0 && dataBLogs[1] && (
                        <div className="blog-content-img-holder">
                          <div className="blog-img-holder">
                            <img
                              src={IMG_URL + dataBLogs[1]?.image}
                              className="blog-img"
                              alt="blog-img"
                            />
                          </div>
                          <div className="overlay-holder"></div>
                          <div className="text-content-holder">
                            <div className="text-holder">
                              <p>{dataBLogs[1]?.title}</p>
                            </div>
                            <Link to={`/blog-inner/${dataBLogs[1]?.id}`}>
                              <div className="btn-holder">
                                <Button className="read-more-btn">
                                  Read More
                                </Button>
                              </div>
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-12 space-holder">
                      {dataBLogs.length > 0 && dataBLogs[2] && (
                        <div className="blog-content-img-holder">
                          <div className="blog-img-holder">
                            <img
                              src={IMG_URL + dataBLogs[2]?.image}
                              className="blog-img"
                              alt="blog-img"
                            />
                          </div>
                          <div className="overlay-holder"></div>
                          <div className="text-content-holder">
                            <div className="text-holder">
                              <p>{dataBLogs[2]?.title}</p>
                            </div>
                            <div className="btn-holder">
                              <Link to={`/blog-inner/${dataBLogs[2]?.id}`}>
                                <Button className="read-more-btn">
                                  Read More
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <div className="col-md-12 space-holder">
                      <div className="blog-content-img-holder">
                        <div className="blog-img-holder">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/Homepage/Home_Blogs/group-people.png"
                            }
                            className="blog-img"
                            alt="blog-img"
                          />
                        </div>
                        <div className="overlay-holder"></div>
                        <div className="text-content-holder">
                          <div className="text-holder">
                            <p>
                              Understanding Auto Insurance: A Comprehensive
                              Guide
                            </p>
                          </div>

                          <div className="btn-holder">
                            <Link to={"/blog-inner"}>
                              <Button className="read-more-btn">
                                Read More
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                {dataBLogs.length > 0 && dataBLogs[0] && (
                  <div className=" col-md-4">
                    <div className="row">
                      <div className="col-md-12 mobile-view-space">
                        <div className="blog-content-img-holder">
                          <div className="blog-img-holder">
                            <img
                              src={IMG_URL + dataBLogs[0]?.image}
                              className="blog-img main-img"
                              alt="blog-img"
                            />
                          </div>
                          <div className="overlay-holder"></div>
                          <div className="text-content-holder">
                            <div className="text-holder">
                              <p>{dataBLogs[0]?.title}</p>
                            </div>

                            <div className="btn-holder">
                              <Link to={`/blog-inner/${dataBLogs[0]?.id}`}>
                                <Button className="read-more-btn">
                                  Read More
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className=" col-md-4">
                  <div className="row">
                    {dataBLogs.length > 0 && dataBLogs[3] && (
                      <div className="col-md-12 space-holder">
                        <div className="blog-content-img-holder">
                          <div className="blog-img-holder">
                            <img
                              src={IMG_URL + dataBLogs[3]?.image}
                              className="blog-img"
                              alt="blog-img"
                            />
                          </div>
                          <div className="overlay-holder"></div>
                          <div className="text-content-holder">
                            <div className="text-holder">
                              <p>{dataBLogs[3]?.title}</p>
                            </div>

                            <div className="btn-holder">
                              <Link to={`/blog-inner/${dataBLogs[3]?.id}`}>
                                <Button className="read-more-btn">
                                  Read More
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {dataBLogs.length > 0 && dataBLogs[4] && (
                      <div className="col-md-12 space-holder">
                        <div className="blog-content-img-holder">
                          <div className="blog-img-holder">
                            <img
                              src={IMG_URL + dataBLogs[4]?.image}
                              className="blog-img"
                              alt="blog-img"
                            />
                          </div>
                          <div className="overlay-holder"></div>
                          <div className="text-content-holder">
                            <div className="text-holder">
                              <p>{dataBLogs[4]?.title}</p>
                            </div>

                            <div className="btn-holder">
                              <Link to={`/blog-inner/${dataBLogs[4]?.id}`}>
                                <Button className="read-more-btn">
                                  Read More
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <div className="col-md-12 space-holder">
                      <div className="blog-content-img-holder">
                        <div className="blog-img-holder">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/Homepage/Home_Blogs/group-people.png"
                            }
                            className="blog-img"
                            alt="blog-img"
                          />
                        </div>
                        <div className="overlay-holder"></div>
                        <div className="text-content-holder">
                          <div className="text-holder">
                            <p>
                              Understanding Auto Insurance: A Comprehensive
                              Guide
                            </p>
                          </div>

                          <div className="btn-holder">
                            <Link to={"/blog-inner"}>
                              <Button className="read-more-btn">
                                Read More
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {dataBLogs?.length > 5 && (
            <Link to={"/blogs"}>
              <div className="view-more-holder">
                <p>View More</p>
                <div className="down-arrow-holder big-arrow">
                  <FontAwesomeIcon className="down-arrow " icon={faAngleDown} />
                </div>

                <div className="down-arrow-holder small-arrow">
                  <FontAwesomeIcon className="down-arrow " icon={faAngleDown} />
                </div>
              </div>
            </Link>
          )}
        </div>
      </section>
    </>
  );
}

export default Home_Blogs;
