import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { postInsurance } from "../../../../utils/apis/common/Common";
import Common_btn_holder from "../../../Common_component/Common_btn/Common_btn_holder";
import Bottom_Successfull_Modal from "../../../Common_component/Bottom_Successfull_Modal/Bottom_Successfull_Modal";
function Health_Wheeler_Insurance_Banner({ imgSrc, formText, bannerText }) {
  const [modalShow, setModalShow] = React.useState(false);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const [startDate, setStartDate] = useState(null); // State for selected start date
  const [endDate, setEndDate] = useState(null);
  const [startDateError, setStartDateError] = useState(null); // State for selected start date
  const [endDateError, setEndDateError] = useState(null); // State for selected end date
  const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState(false); // State for start date picker visibility
  const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState(false); // State for end date picker visibility

  const handleStartDateClick = () => {
    setIsStartDatePickerOpen(!isStartDatePickerOpen); // Toggle calendar visibility
  };

  const handleEndDateClick = () => {
    setIsEndDatePickerOpen(!isEndDatePickerOpen); // Toggle calendar visibility
  };

  const options = [
    { value: "5 lakh", label: "5 lakh" },
    { value: "10 Lakh", label: "10 Lakh" },
    { value: "15 lakh", label: " 15 lakh" },
    { value: "20 Lakh", label: "20 Lakh" },
    { value: "50 Lakh", label: " 50 Lakh" },
    { value: "1 crore", label: "1 crore" },
    { value: "2 crore", label: "2 crore" },
    { value: "3 crore", label: "3 crore" },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, pad with zero
    const year = date.getFullYear(); // Get full year

    return `${day}/${month}/${year}`; // Return formatted date
  };
  const onSubmit = async (data) => {
    console.log(data, "data data");
    const formattedExpiryDate = formatDate(data.expiry_date);
    if (startDate == null && endDate == null) {
      setStartDateError("start Date Required");
      setEndDateError("End Date Required");
      return;
    }
    if (startDate == null) {
      setStartDateError("start Date Required");
      return;
    }
    if (endDate == null) {
      setEndDateError("End Date Required");
      return;
    }
    const formattedData = {
      first_name: data.first_name,
      last_name: data.last_name,
      county_travelling_to: data.county_travelling_to,
      // expiry_date: formattedExpiryDate,
      end_date: data.end_date,
      start_date: data.start_date,
      type: "Travel",
    };
    const res = await postInsurance(formattedData);
    if (res?.success) {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        reset();
      }, 3000);
    } else {
      // if (res?.message?.email) {
      //   setError("email", { message: res?.message?.email });
      // }
      // if (res?.message?.contact_no) {
      //   setError("contact_no", { message: res?.message?.contact_no });
      // }
    }
  };
  return (
    <>
      <section className="Wheeler_Insurance_Banner">
        <div className="banner_content_holder">
          <div className="banner_img_holder">
            <img
              src={process.env.PUBLIC_URL + imgSrc}
              className="banner-img"
              alt=""
            />
          </div>

          <div className="banner-overlay"></div>

          <div className="banner-overlay-content">
            <div className="container  banner-container">
              <div className="row  banner-overlay-content-holder ">
                <div className="col-xl-5 col-lg-5 col-md-12">
                  <div className="banner-heading-holder">
                    <h2>{bannerText}</h2>
                  </div>
                </div>

                <div className="col-lg-6 col-lg-7 col-md-12">
                  <div className="Inquiry-form-holder">
                    <div>
                      <div className="heading-img-text-holder">
                        <div className="heading_img_holder">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/Homepage/Videos_Come_Life/Videos-Come-Life-img.png"
                            }
                            className="heading-img"
                            alt="heading-img"
                          />
                        </div>
                        <div className="form-heading-holder">
                          <h3>{formText}</h3>
                        </div>
                      </div>
                      <div className="Inquiry-main-form">
                        <Form className="form-holder">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Enter First Name"
                                  {...register("first_name", {
                                    required: "First Name is required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.first_name,
                                  })}
                                  onKeyDown={(event) => {
                                    if (!/[A-Z-a-z ]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {errors.first_name && (
                                  <div className="invalid-feedback">
                                    {errors.first_name.message}
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Enter Last Name"
                                  {...register("last_name", {
                                    required: "Last Name is required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.last_name,
                                  })}
                                  onKeyDown={(event) => {
                                    if (!/[A-Z-a-z ]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {errors.last_name && (
                                  <div className="invalid-feedback">
                                    {errors.last_name.message}
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-field-holder">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Country traveling to"
                                  {...register("county_travelling_to", {
                                    required:
                                      "county_travelling_to is required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.county_travelling_to,
                                  })}
                                />
                                {errors.county_travelling_to && (
                                  <div className="invalid-feedback">
                                    {errors.county_travelling_to.message}
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder ">
                              <Form.Group
                                className="date-holder"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Starting Date"
                                  value={
                                    startDate
                                      ? startDate.toLocaleDateString()
                                      : ""
                                  } // Show selected date in input
                                  readOnly // Make input read-only
                                />

                                <div
                                  className="date-text-holder"
                                  onClick={handleStartDateClick}
                                >
                                  <p>
                                    {"dd/mm/yyyy"} {/* Placeholder text */}
                                    <FontAwesomeIcon
                                      className="calender-icon"
                                      icon={faCalendarDays}
                                    />
                                  </p>
                                </div>

                                {isStartDatePickerOpen && (
                                  <Controller
                                    name="start_date" // Field name in form data
                                    control={control}
                                    defaultValue={null} // Set initial value
                                    render={({ field: { onChange } }) => (
                                      <DatePicker
                                        selected={startDate}
                                        onChange={(date) => {
                                          setStartDateError("");
                                          setStartDate(date); // Update selected date
                                          onChange(date); // Update form state with the selected date
                                          setIsStartDatePickerOpen(false); // Close calendar after date selection
                                        }}
                                        inline // Show calendar inline
                                      />
                                    )}
                                  />
                                )}
                                {startDateError && (
                                  <sup className="text-danger">
                                    {startDateError}
                                  </sup>
                                )}
                              </Form.Group>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder ">
                              <Form.Group
                                className="date-holder"
                                controlId="exampleForm.ControlInput2"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Ending Date"
                                  value={
                                    endDate ? endDate.toLocaleDateString() : ""
                                  } // Show selected date in input
                                  readOnly // Make input read-only
                                />

                                <div
                                  className="date-text-holder"
                                  onClick={handleEndDateClick}
                                >
                                  <p>
                                    {"dd/mm/yyyy"} {/* Placeholder text */}
                                    <FontAwesomeIcon
                                      className="calender-icon"
                                      icon={faCalendarDays}
                                    />
                                  </p>
                                </div>

                                {isEndDatePickerOpen && (
                                  <Controller
                                    name="end_date" // Field name in form data
                                    control={control}
                                    defaultValue={null} // Set initial value
                                    render={({ field: { onChange } }) => (
                                      <DatePicker
                                        selected={endDate}
                                        onChange={(date) => {
                                          setEndDateError("");
                                          setEndDate(date); // Update selected date
                                          onChange(date); // Update form state with the selected date
                                          setIsEndDatePickerOpen(false); // Close calendar after date selection
                                        }}
                                        inline // Show calendar inline
                                      />
                                    )}
                                  />
                                )}
                                {endDateError && (
                                  <sup className="text-danger">
                                    {endDateError}
                                  </sup>
                                )}
                              </Form.Group>
                            </div>
                          </div>
                        </Form>

                        <Common_btn_holder
                          onClick={handleSubmit(onSubmit)}
                          // onClick={() => setModalShow(true)}
                          btnText={"Get Quote"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Bottom_Successfull_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        text={"Quote Generated Successfully!"}
      />
    </>
  );
}

export default Health_Wheeler_Insurance_Banner;
