import React from "react";
import "./Vision_Mission_Value.css";

function Vision_Mission_Value() {
  return (
    <>
      <section className="Vision_Mission_Value-holder">
        <div className="container">
          <div className="Vision_Mission_Value">
            <div className="section-heading-holder">
              <h3>Vision, Mission and Value</h3>
              <p>Guiding Our Commitment to You</p>
            </div>

            <div className="Vision_Mission_Value_card_holder">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-3 my-3">
                  <div className="Vision_Mission_Value_card">
                    <div className="img-overlay-holder">
                      <div className="card-img-holder">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/About_Us/Vision_Mission_Value/vision.png"
                          }
                          className="vision-card-img"
                          alt="vision-card-img"
                        />
                      </div>

                      <div className="top-overlay-holder">
                        <p>Vision</p>
                      </div>
                    </div>

                    <div className="card-content-holder">
                      <p>
                      At Hitech Solutions, our vision is to be the most trusted and innovative provider of
insurance solutions in India. We aim to empower individuals and businesses with
peace of mind through reliable coverage and exceptional customer service, setting
the standard for excellence in the insurance industry.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-3 my-3">
                  <div className="Vision_Mission_Value_card">
                    <div className="img-overlay-holder">
                      <div className="card-img-holder">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/About_Us/Vision_Mission_Value/vision.png"
                          }
                          className="vision-card-img"
                          alt="vision-card-img"
                        />
                      </div>

                      <div className="top-overlay-holder">
                        <p>Mission</p>
                      </div>
                    </div>

                    <div className="card-content-holder">
                      <p>
                      Our mission at Hitech Solutions is to provide individuals and businesses with tailored
insurance solutions that meet their unique needs. We are dedicated to delivering
outstanding customer service, fostering long-term relationships, and continuously
enhancing our offerings through innovation and collaboration with industry-leading
partners.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-3 my-3">
                  <div className="Vision_Mission_Value_card">
                    <div className="img-overlay-holder">
                      <div className="card-img-holder">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/About_Us/Vision_Mission_Value/vision.png"
                          }
                          className="vision-card-img"
                          alt="vision-card-img"
                        />
                      </div>

                      <div className="top-overlay-holder">
                        <p>Value</p>
                      </div>
                    </div>

                    <div className="card-content-holder">
                      <p>
                      At Hitech Solutions, we prioritize our customers by delivering personalized service
and support to ensure their satisfaction. We uphold integrity through transparency
and honesty in all our dealings. Our commitment to excellence drives continuous
improvement in our processes and offerings. We embrace innovation and
collaboration to create tailored solutions that meet the evolving needs of our clients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Vision_Mission_Value;
