import React from "react";
import Other_Service_banner from "../../../../Common_component/Other_Service_banner/Other_Service_banner";
import Breadcrumbs_Holder from "../../../../Common_component/Breadcrumbs_Holder/Breadcrumbs_Holder";
import Business_Insurance_Card from "./Business_Insurance_Card/Business_Insurance_Card"

function Business_Insurance() {
  const breadcrumbs = [
    { label: "Other", link: "/other", active: false },
    { label: "Business Insurance", link: "", active: true },
  ];
  return (
    <>
      <Other_Service_banner bannerText={" Business Insurance"} />

      <Breadcrumbs_Holder breadcrumbs={breadcrumbs} />

      <Business_Insurance_Card/>
    </>
  );
}

export default Business_Insurance;
