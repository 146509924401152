import React from "react";
import "./BannerTab.css";
import { Nav, Tab } from "react-bootstrap";
import FirstStep from "./AllStep/FirstStep";
import SecondStep from "./AllStep/SecondStep";
import ThirdStep from "./AllStep/ThirdStep";
import FourStep from "./AllStep/FourStep";
import FifthStep from "./AllStep/FifthStep";
import SixStep from "./AllStep/SixStep";
import Other_Insurance_Modal from "../../../Common_component/Other_Insurance_Modal/Other_Insurance_Modal";

const BannerTab = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <section className="banner-tab">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav variant="pills" className="tabs-holder">
            <Nav.Item>
              <Nav.Link eventKey="first">
                <div className="tab-box-holder">
                  <div className="img-holder">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/image/Homepage/Tabs/Solid.png"
                      }
                      className="tab-box-img"
                      alt="..."
                    />
                  </div>
                  <div className="tab-text-holder">
                    <h4>4 Wheeler</h4>
                  </div>
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
                {" "}
                <div className="tab-box-holder">
                  <div className="img-holder">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/image/Homepage/Tabs/bike.png"
                      }
                      className="tab-box-img"
                      alt="..."
                    />
                  </div>
                  <div className="tab-text-holder">
                    <h4>2 Wheeler</h4>
                  </div>
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => setModalShow(true)}>
              <Nav.Link eventKey="third">
                {" "}
                <div className="tab-box-holder">
                  <div className="img-holder">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/image/Homepage/Tabs/XMLID.png"
                      }
                      className="tab-box-img"
                      alt="..."
                    />
                  </div>
                  <div className="tab-text-holder">
                    <h4>Other</h4>
                  </div>
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="four">
                {" "}
                <div className="tab-box-holder">
                  <div className="img-holder">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/image/Homepage/Tabs/travel-insurance.png"
                      }
                      className="tab-box-img"
                      alt="..."
                    />
                  </div>
                  <div className="tab-text-holder">
                    <h4>Travel</h4>
                  </div>
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="five">
                {" "}
                <div className="tab-box-holder">
                  <div className="img-holder">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/image/Homepage/Tabs/home.png"
                      }
                      className="tab-box-img"
                      alt="..."
                    />
                  </div>
                  <div className="tab-text-holder">
                    <h4>Home</h4>
                  </div>
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="six">
                {" "}
                <div className="tab-box-holder">
                  <div className="img-holder">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/image/Homepage/Tabs/health-insurance.png"
                      }
                      className="tab-box-img"
                      alt="..."
                    />
                  </div>
                  <div className="tab-text-holder">
                    <h4>Health</h4>
                  </div>
                </div>
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="first">
              <FirstStep />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <SecondStep />
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <ThirdStep />
            </Tab.Pane>
            <Tab.Pane eventKey="four">
              <FourStep />
            </Tab.Pane>
            <Tab.Pane eventKey="five">
              <FifthStep />
            </Tab.Pane>
            <Tab.Pane eventKey="six">
              <SixStep />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </section>

      <Other_Insurance_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default BannerTab;
