import React, { useState, useEffect, useContext } from "react";
import "./Four_Wheeler_Insurance_Banner.css";
import { Form } from "react-bootstrap";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { postInsurance } from "../../../../utils/apis/common/Common";
import Common_btn_holder from "../../../Common_component/Common_btn/Common_btn_holder";
import Bottom_Successfull_Modal from "../../../Common_component/Bottom_Successfull_Modal/Bottom_Successfull_Modal";
function Four_Wheeler_Insurance_Banner({ imgSrc, formText, bannerText }) {
  const [modalShow, setModalShow] = React.useState(false);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data, "data data");
    const formattedData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      city: data.city,
      vr_no: data.vr_no,
      contact_number: data.contact_number,
      insurance_type: data.insurance_type,
      type: "4 Wheeler",
    };
    const res = await postInsurance(formattedData);
    if (res?.success) {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        reset();
      }, 3000);
    } else {
      // if (res?.message?.email) {
      //   setError("email", { message: res?.message?.email });
      // }
      // if (res?.message?.contact_no) {
      //   setError("contact_no", { message: res?.message?.contact_no });
      // }
    }
  };

  return (
    <>
      <section className="Wheeler_Insurance_Banner">
        <div className="banner_content_holder">
          <div className="banner_img_holder">
            <img
              src={process.env.PUBLIC_URL + imgSrc}
              className="banner-img"
              alt=""
            />
          </div>

          <div className="banner-overlay"></div>

          <div className="banner-overlay-content">
            <div className="container  banner-container">
              <div className="row  banner-overlay-content-holder ">
                <div className="col-xl-5 col-lg-5 col-md-12">
                  <div className="banner-heading-holder">
                    <h2>{bannerText}</h2>
                  </div>
                </div>

                <div className="col-lg-6 col-lg-7 col-md-12">
                  <div className="Inquiry-form-holder">
                    <div>
                      <div className="heading-img-text-holder">
                        <div className="heading_img_holder">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/Homepage/Videos_Come_Life/Videos-Come-Life-img.png"
                            }
                            className="heading-img"
                            alt="heading-img"
                          />
                        </div>
                        <div className="form-heading-holder">
                          <h3>{formText}</h3>
                        </div>
                      </div>
                      <div className="Inquiry-main-form">
                        <Form className="form-holder">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Enter First Name"
                                  {...register("first_name", {
                                    required: "First Name is required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.first_name,
                                  })}
                                  onKeyDown={(event) => {
                                    if (!/[A-Z-a-z ]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {errors.first_name && (
                                  <div className="invalid-feedback">
                                    {errors.first_name.message}
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Enter Last Name"
                                  {...register("last_name", {
                                    required: "Last Name is required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.last_name,
                                  })}
                                  onKeyDown={(event) => {
                                    if (!/[A-Z-a-z ]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {errors.last_name && (
                                  <div className="invalid-feedback">
                                    {errors.last_name.message}
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  name="number"
                                  placeholder="Vehicles Registration Number"
                                  {...register("vr_no", {
                                    required:
                                      "Vehicles Registration Number is required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.vr_no,
                                  })}
                                />
                                {errors.vr_no && (
                                  <div className="invalid-feedback">
                                    {errors.vr_no.message}
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Enter City"
                                  {...register("city", {
                                    required: "city is required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.city,
                                  })}
                                />
                                {errors.city && (
                                  <div className="invalid-feedback">
                                    {errors.city.message}
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  className={classNames("", {
                                    "is-invalid": errors?.contact_number,
                                    "is-valid": getValues("contact_number"),
                                  })}
                                  type="text"
                                  name="contact_number"
                                  placeholder="Enter Mobile Number"
                                  {...register("contact_number", {
                                    required: "Contact No is required",
                                    minLength: {
                                      value: 10,
                                      message:
                                        "Number should be at least 10 characters",
                                    },
                                  })}
                                  onKeyDown={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      if (event.key !== "Backspace") {
                                        event.preventDefault();
                                      }
                                    }
                                  }}
                                  maxLength={10}
                                />
                                {errors?.contact_number && (
                                  <sup className="text-danger">
                                    {errors?.contact_number?.message}
                                  </sup>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
                              <Form.Group
                                className=""
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  name="email"
                                  placeholder="E-mail Address"
                                  {...register("email", {
                                    required: "Email Id required",
                                    pattern: {
                                      value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
                                      message: "Email Id Invalid",
                                    },
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.email,
                                  })}
                                />
                                {errors.email && (
                                  <div className="invalid-feedback">
                                    {errors.email.message}
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                          </div>
                          <div className="radio-text-holder">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="heading-holder">
                                  <h4>Insurance type : </h4>
                                </div>
                              </div>

                              <div className="col-md-9">
                                <div className="radio-holder">
                                  <div className="row">
                                    <Controller
                                      name="insurance_type"
                                      control={control}
                                      defaultValue="" // Default value set to empty
                                      rules={{
                                        required: "Insurance type is required",
                                      }} // Add validation rule
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <>
                                          <div className="col-xl-3 col-lg-4 col-md-3 col-sm-3 col-6">
                                            <Form.Check
                                              inline
                                              label="Comprehensive"
                                              value="Comprehensive"
                                              type="radio"
                                              checked={
                                                value === "Comprehensive"
                                              }
                                              onChange={() =>
                                                onChange("Comprehensive")
                                              } // Use onChange to set the value
                                            />
                                          </div>

                                          <div className="col-xl-3 col-lg-4 col-md-3 col-sm-3 col-6">
                                            <Form.Check
                                              inline
                                              label="OD"
                                              value="OD"
                                              type="radio"
                                              checked={value === "OD"}
                                              onChange={() => onChange("OD")} // Use onChange to set the value
                                            />
                                          </div>

                                          <div className="col-xl-3 col-lg-4 col-md-3 col-sm-3 col-6">
                                            <Form.Check
                                              inline
                                              label="Third Party"
                                              value="Third Party"
                                              type="radio"
                                              checked={value === "Third Party"}
                                              onChange={() =>
                                                onChange("Third Party")
                                              } // Use onChange to set the value
                                            />
                                          </div>

                                          <div className="col-xl-3 col-lg-4 col-md-3 col-sm-3 col-6">
                                            <Form.Check
                                              inline
                                              label="Not sure"
                                              value="Not sure"
                                              type="radio"
                                              checked={value === "Not sure"}
                                              onChange={() =>
                                                onChange("Not sure")
                                              } // Use onChange to set the value
                                            />
                                          </div>
                                        </>
                                      )}
                                    />
                                  </div>
                                  {/* Show error message if insurance_type is missing */}
                                  {errors?.insurance_type && (
                                    <sup className="text-danger">
                                      {errors.insurance_type.message}
                                    </sup>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>

                        <Common_btn_holder
                          onClick={handleSubmit(onSubmit)}
                          // onClick={() => setModalShow(true)}
                          btnText={"Get Quote"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Bottom_Successfull_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        text={"Quote Generated Successfully!"}
      />
    </>
  );
}

export default Four_Wheeler_Insurance_Banner;
