import React from "react";

function Types_of_Two_Wheeler({ typeHeading }) {
  return (
    <>
      <section className="Types_of_Four_Wheeler">
        <div className="container">
          <div className="Types_of_Four_Wheeler_holder">
            <div className="heading-holder">
              <h3>{typeHeading}</h3>
            </div>
            <div className="Types_of_Four_Wheeler_content">
              <div className="row">
                <div className="col-md-8">
                  <div className="content-list-holder">
                    <div className="text-holder">
                      <h3>Third-Party Liability Insurance:</h3>

                      <ul>
                        <li>
                          Mandatory by law in most countries, including India.
                        </li>
                        <li>Does not cover damages to your own bike.</li>
                        <li>
                          Covers legal liability for damages to another person’s
                          property or injuries in the event of an accident.
                        </li>
                      </ul>
                    </div>

                    <div className="text-holder">
                      <h3>Comprehensive Car Insurance:</h3>
                      <ul>
                        <li> Offers more extensive coverage.</li>

                        <li>
                          Covers damages to both your car and third-party
                          property.
                        </li>

                        <li>
                          Protects against natural calamities, theft, vandalism,
                          fire, and other events beyond your control.
                        </li>

                        <li>Can include add-ons for personalized coverage.</li>
                      </ul>
                    </div>

                    <div className="text-holder">
                      <h3>Personal Accident Cover:</h3>
                      <ul>
                        <li>
                          Provides financial assistance for medical expenses or
                          compensation in case of accidental injury or death of
                          the owner/driver.
                        </li>

                        <li>
                          <b>Zero Depreciation Cover (Add-on):</b> Ensures full
                          claim without deducting depreciation value from the
                          claim amount.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-12 col-sm-6 col-6">
                      <div className="Types_of_Four_Wheeler_add_img_holder">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/Services/Types_of_Four_Wheeler_add_img/add-img-1.png"
                          }
                          className="Types_of_Four_Wheeler_add_img"
                          alt="Types_of_Four_Wheeler_add_img"
                        />
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-6 col-6">
                      <div className="Types_of_Four_Wheeler_add_img_holder">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/Services/Types_of_Four_Wheeler_add_img/add-img-2.png"
                          }
                          className="Types_of_Four_Wheeler_add_img"
                          alt="Types_of_Four_Wheeler_add_img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Types_of_Two_Wheeler;
