import React from "react";
import "./Why_Need_Health_Insurance.css";

function Why_Need_Health_Insurance() {
  return (
    <>
      <section className="Types_of_Four_Wheeler Why_Need_Health_Insurance">
        <div className="container">
          <div className="Types_of_Four_Wheeler_holder">
            <div className="heading-holder">
              <h3>Why Do You Need Health Insurance?</h3>
            </div>
            <div className="Types_of_Four_Wheeler_content">
              <div className="text-holder main-text-holder">
                <p>
                  Investing in health insurance is essential for your health and
                  financial well-being. It provides a crucial safety net against
                  the unpredictability of medical expenses, ensuring you’re
                  prepared for whatever life throws your way.
                </p>
                <p>
                  <b>Every year</b> millions of people find themselves facing
                  unexpected medical emergencies.{" "}
                  <b>In fact, over 30 million</b>
                  individuals visit hospitals due to sudden health issues.
                  Without health insurance, these visits can lead to staggering
                  bills that can reach up <b>to ₹5 lakh or more</b>, depending
                  on the severity of the condition and the treatment required.
                </p>

                <p>
                  Imagine a scenario where a family member is suddenly diagnosed
                  with a critical illness. The costs of hospitalization,
                  treatments, and medications can quickly escalate, resulting in
                  financial stress and emotional turmoil. Many families drain
                  their savings or, worse, go into debt to cover these
                  unexpected expenses. This is not just a statistic—it’s a
                  reality that affects countless families every year.
                </p>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <div className="content-list-holder">
                    <ol>
                      <li>
                        <div className="text-holder">
                          <h3>Financial Security:</h3>
                          <p>
                            Health insurance helps cover the high costs of
                            medical care, from routine check-ups to major
                            surgeries, reducing the burden of unexpected
                            expenses.
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="text-holder">
                          <h3> Access to Quality Care:</h3>
                          <p>
                            With insurance, you can access a network of
                            healthcare providers, ensuring you receive timely
                            and quality medical attention when needed.
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="text-holder">
                          <h3>Preventive Services:</h3>{" "}
                          <p>
                            Many plans cover preventive services, such as
                            vaccinations and screenings, which can detect health
                            issues early and keep you healthier in the long run.
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="text-holder">
                          <h3>Managing Chronic Conditions: </h3>{" "}
                          <p>
                            If you have a chronic illness like diabetes or heart
                            disease, insurance helps cover ongoing treatments,
                            medications, and specialist visits.
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="text-holder">
                          <h3>Peace of Mind:</h3>{" "}
                          <p>
                            Knowing you're covered in case of an emergency or
                            serious illness gives you peace of mind, allowing
                            you to focus on your health and well-being.
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="text-holder">
                          <h3>Legal Requirements:</h3>
                          <p>
                            In some countries, having health insurance is a
                            legal requirement, and not having it could result in
                            penalties.
                          </p>
                          <p>
                            Health insurance is an investment in your health and
                            financial future, providing a safety net against the
                            unpredictability of medical expenses.
                          </p>
                        </div>
                      </li>

                      <div className="text-holder">
                        <h3>
                          <b>Take Action Today!</b>
                        </h3>
                        <p>
                          Don’t wait for a medical emergency to consider your
                          health insurance options. Invest in your health and
                          financial future today by choosing a plan that fits
                          your needs. Our team is here to help you find the
                          perfect coverage tailored to your lifestyle and
                          budget. Contact us now to get started and secure your
                          peace of mind with comprehensive health insurance!
                          Protect yourself and your family before it’s too late!
                        </p>
                      </div>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Why_Need_Health_Insurance;
