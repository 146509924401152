import React from "react";
import Common_banner from "../Common_component/Common_banner/Common_banner";
import Terms_Conditions_Content from "./Terms_Conditions_Content/Terms_Conditions_Content";
import About_Quick_Inquiry from "../About_Us/About_Quick_Inquiry/About_Quick_Inquiry";

function Terms_Conditions() {
  return (
    <>
      <Common_banner
        imgSrc={"/assets/image/Terms_Conditions/terms-conditions-banner.png"}
        mainHeading={"Terms & Conditions"}
        heading={"Terms & Conditions"}
      />

      <Terms_Conditions_Content />

      <About_Quick_Inquiry/>
    </>
  );
}

export default Terms_Conditions;
