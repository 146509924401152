import React from "react";
import "./Happy_Client_Card.css";

function Happy_Client_Card({ isActive, feedback, clientName, clientImgSrc, imgSrc}) {
  console.log(imgSrc,"imgSrc");
  console.log(clientName,'clientName clientName');
  
  return (
    <div className={`Happy_Clients_card ${isActive ? "Happy_Clients_active_card" : ""}`}>
      <div className={`main-content-holder ${isActive ? "active-card" : ""}`}>
        <div className="sign-holder">
          <img
            src={process.env.PUBLIC_URL + imgSrc}
            className="sign-img"
            alt="sign-img"
          />
        </div>
        <div className="text-holder">
          <p>{feedback}</p>
        </div>
      </div>
      <div className="client-img-name-holder">
        <div className="client-img-holder">
          <img src={clientImgSrc} className="client-img" alt="client-img" />
        </div>
        <div className="client-name-holder">
          <p>{clientName}</p>
        </div>
      </div>
    </div>
  );
}

export default Happy_Client_Card;
