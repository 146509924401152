import React, { useState } from "react";
import "./Insurance_Services.css";
import { Button } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";
import Wheeler_Insurance_Modal from "../../Common_component/Wheeler_Insurance_Modal/Wheeler_Insurance_Modal";

function Insurance_Services() {
  const [modalShow, setModalShow] = React.useState(false);
  const insuranceServicesData = [
    {
      type: "4 Wheeler",
      title: "Vehicle Insurance",
      titleBg: "card-bg-img-img",
      description:
        "Provides Financial Protection Against Damages, Theft, And Liability From Accidents Involving Your Vehicle.",
      imgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Insurance_Services/Vehicle.png",
      imgSrcBg:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Insurance_Services/vehicle-bg.png",
    },
    {
      type: "Health",
      title: "Health Insurance",
      titleBg: "card-bg-img-img",
      description:
        "Helps Cover Medical Expenses, Ensuring You Receive The Care You Need Without Financial Strain.",
      imgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Insurance_Services/Health.png",
      imgSrcBg:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Insurance_Services/health-bg.png",
    },
    {
      type: "2 Wheeler",
      title: "2 Wheeler Insurance",
      titleBg: "card-bg-img-img",
      description:
        "Provides Essential Protection Against Accidents, Theft, And Damage, Ensuring Peace Of Mind For Riders.",
      imgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Insurance_Services/bike.png",
      imgSrcBg:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Insurance_Services/bike-bg.png",
    },
    {
      type: "Home",
      title: "Home Insurance",
      titleBg: "card-bg-img-img",
      description:
        "Protects Your Property And Belongings From Risks Like Fire, Theft, And Natural Disasters.",
      imgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Insurance_Services/home.png",
      imgSrcBg:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Insurance_Services/home-bg.png",
    },
    {
      type: "Travel",
      title: "Travel Insurance",
      titleBg: "card-bg-img-img",
      description:
        "Offers Protection Against Unexpected Events And Emergencies While You're On The Go.",
      imgSrc:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Insurance_Services/Life.png",
      imgSrcBg:
        process.env.PUBLIC_URL +
        "/assets/image/Homepage/Insurance_Services/life-bg.png",
    },
  ];
  const [insuranceType, setInsuranceType] = useState("");

  const handleQuoteModal = (type) => {
    console.log(type, "type ttttttttttttttt");

    setInsuranceType(type);
    setModalShow(true); // Open Get_Quote_Modal
    // props.onHide(); // Close Other_Insurance_Modal
  };

  return (
    <>
      <section className="Insurance_Services_holder">
        <div className="container">
          <div className="Insurance_Services">
            <div className="section-heading-holder">
              <h3>Insurance Plans</h3>
              <p> Designed to Protect Every Corner of Your Life.</p>
            </div>

            <div className="Insurance_Services_card-holder">
              <Swiper
                spaceBetween={30}
                slidesPerView={4}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                className="Insurance_Services-slider"
                modules={[Autoplay, Pagination]}
                // loop={true}
                // autoplay={{
                //   delay: 2500,
                //   disableOnInteraction: false,
                // }}
                pagination={{
                  dynamicBullets: true,
                  clickable: true,
                }}
                breakpoints={{
                  // when window width is >= 320px
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },

                  // when window width is >= 992px
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  // when window width is >= 1024px
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },

                  // when window width is >= 1200px
                  1299: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
              >
                {insuranceServicesData.map((service, index) => (
                  <SwiperSlide>
                    <div className="card-holder">
                      <div className="bg-img-holder">
                        <img
                          src={service.imgSrcBg}
                          className="card-bg-img-img"
                          alt={service.titleBg}
                        />
                      </div>
                      <div className="card-content-holder">
                        <div className="row me-0 ms-0">
                          <div className="col-md-9 col-9">
                            <div className="content-holder">
                              <div className="text-holder">
                                <h3>{service.title}</h3>
                                <p>{service.description}</p>
                              </div>

                              <div className="btn-holder">
                                <Button
                                  className="Inquiry_btn"
                                  onClick={() =>
                                    handleQuoteModal(service?.type)
                                  }
                                >
                                  Inquiry
                                </Button>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3 col-3">
                            <div className="card-img-holder">
                              <img
                                src={service.imgSrc}
                                className="Insurance-img-holder"
                                alt={service.title}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <Wheeler_Insurance_Modal
        modalImg={
          "/assets/image/Services/Types_of_Four_Wheeler_add_img/Two-wheeler-ins.png"
        }
        insuranceType={insuranceType}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Insurance_Services;
