import React from "react";
import "./Happy_Clients.css";
import Common_btn_holder from "../../Common_component/Common_btn/Common_btn_holder";
import Happy_Client_Card from "../../Common_component/Happy_Client_Card/Happy_Client_Card";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';
function Happy_Clients({dataBrand,IMG_URL}) {
  console.log(dataBrand,'dataBrand dataBrand');
  
  return (
    <section className="Happy_Clients_holder">
      <div className="container">
        <div className="Happy_Clients">
          <div className="section-heading-holder">
            <h3>what our happy clients are saying</h3>
            <p>Customer Happy stories</p>
          </div>

          <div className="Happy_Clients_card_holder">
            <div className="row">
            {/* {dataBrand?.slice(0, 3).map((val, index) => ( */}
            {dataBrand.length > 0 && dataBrand[0] && (
              <div className="col-md-4 fisrt-card">
                <Happy_Client_Card              
                 isActive={false}
                  feedback={parse(dataBrand[0]?.description)}
                  clientName={dataBrand[0]?.name}
                  imgSrc={"/assets/image/Homepage/Happy_Clients/sign.png"}
                  clientImgSrc={
                   IMG_URL +
                   dataBrand[0]?.image
                  }
                />
              </div>   )}
              {/* ))} */}
              {dataBrand.length > 0 && dataBrand[1] && (
              <div className="col-md-4 middle-card">
                <Happy_Client_Card
                  isActive={true}
                  feedback={parse(dataBrand[1]?.description)}
                  clientName={dataBrand[1]?.name}
                  imgSrc={"/assets/image/Homepage/Happy_Clients/sign.png"}
                  clientImgSrc={
                   IMG_URL +
                   dataBrand[1]?.image
                  }
                />
              </div>   )}

              {dataBrand.length > 0 && dataBrand[2] && (
              <div className="col-md-4 last-card">
                <Happy_Client_Card
                  isActive={false}
                  feedback={parse(dataBrand[2]?.description)}
                  clientName={dataBrand[2]?.name}
                  imgSrc={"/assets/image/Homepage/Happy_Clients/sign.png"}
                  clientImgSrc={
                   IMG_URL +
                   dataBrand[0]?.image
                  }
                />
              </div> 
                 )}
            </div>

            {dataBrand?.length > 3  &&(
            <Link to={"/testimonials"}>
              <Common_btn_holder btnText={"View More"} />
            </Link>
             )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Happy_Clients;
