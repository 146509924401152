import React, { useState, useEffect, useContext } from "react";
import "./Banner/Banner.css";
import Banner from "./Banner/Banner";
import WhyIsHitech from "./WhyIsHitech/WhyIsHitech";
import Generate_Quick_Inquiry from "./Generate_Quick_Inquiry/Generate_Quick_Inquiry";
import About_Hitech from "./About_Hitech/About_Hitech";
import Insurance_Services from "./Insurance_Services/Insurance_Services";
import Proud_Partner from "./Proud_Partner/Proud_Partner";
import Insurance_Journey from "./Insurance_Journey/Insurance_Journey";
import Happy_Clients from "./Happy_Clients/Happy_Clients";
import Home_Blogs from "./Home_Blogs/Home_Blogs";
import Videos_Come_Life from "./Videos_Come_Life/Videos_Come_Life";
import { Context } from "../../utils/context";
import { getAllProudtoPartner,getAllDataHappyCustomerData,getBlogs,getVideos } from "../../utils/apis/common/Common";
function Home() {

  const [data, setData] = useState();
  const {  IMG_URL } = useContext(Context);
  const getAllData = async () => {
      const res = await getAllProudtoPartner();
   
      if (res?.success) {
          setData(res?.data?.list);
      } else {
          console.error('Error fetching  data:', res?.error);
      }
  }

  console.log(data,"data data");
  
  const [dataBrand, setDataBrand] = useState();
  const getAllDataHappyCustomer = async () => {
    const res = await getAllDataHappyCustomerData();
    
    if (res?.success) {
      setDataBrand(res?.data?.list);
    } else {
        console.error('Error fetching  data:', res?.error);
    }
}

const [dataBLogs, setDataBlogs] = useState();
const getAllDataBlogs = async () => {
  const res = await getBlogs();
  
  if (res?.success) {
    setDataBlogs(res?.data?.list);
  } else {
      console.error('Error fetching  data:', res?.error);
  }
}

const [dataVideo, setDataVideo] = useState();
const getAllDataVideo = async () => {
  const res = await getVideos();
  
  if (res?.success) {
    setDataVideo(res?.data?.list);
  } else {
      console.error('Error fetching  data:', res?.error);
  }
}
console.log(dataVideo,"dataVideo dataVideo");

  useEffect(() => {
    getAllDataVideo();
    getAllDataBlogs();
      getAllData();
      getAllDataHappyCustomer();
  }, []);
  return (
    <>
      <Banner />
      <WhyIsHitech />
      <Generate_Quick_Inquiry />
      <About_Hitech />
      <Insurance_Services />
      {data?.length > 0 && <Proud_Partner data={data} IMG_URL={IMG_URL}/>}
      <Insurance_Journey />
      {dataBrand?.length > 0 && <Happy_Clients dataBrand={dataBrand} IMG_URL={IMG_URL} />}
      {dataBLogs?.length > 0 && <Home_Blogs dataBLogs={dataBLogs} IMG_URL={IMG_URL} />}
      {dataVideo?.length > 0 && <Videos_Come_Life dataVideo={dataVideo} IMG_URL={IMG_URL} />}
     
    </>
  );
}

export default Home;
