import React, { useState, useEffect, useContext } from "react";
import "./Blog_inner_content.css";
import Comments_card_holder from "../../../Common_component/Comments_card_holder/Comments_card_holder";
import Leave_Comment from "../Leave_Comment/Leave_Comment";

import parse from 'html-react-parser';
function Blog_inner_content({data ,getAllData}) {
  // Create functions to handle smooth scrolling using ids
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

 

  return (
    <>
      <section className="Blog_inner_content">
        <div className="container">
          <div className="Blog_inner_content_holder">
            <div className="main-text-holder">
              <p>
                {parse(data?.description || "")}
              </p>
            </div>

            <div className="list-side-content-holder">
              <div className="row">
                <div className="col-lg-3 col-md-4">
                  <div className="filter-list-holder">
                    {data?.blogpoints?.map((val) => (
                      <>
                        <div className="text-holder"onClick={() => scrollToSection(val?.id)}>
                          <p>{val?.point}</p>
                        </div>
                        <hr /></>))}
                    {/* <div className="text-holder" >
                      <p>Types of Auto Insurance Coverage</p>
                    </div>
                    <hr />

                    <div className="text-holder" onClick={() => scrollToSection("Coverage")}>
                      <p>Auto insurance is not just a legal requirement</p>
                    </div>
                    <hr />

                    <div className="text-holder" onClick={() => scrollToSection("legal_requirement")}>
                      <p>Factors That Affect Your Auto Insurance Premium</p>
                    </div> */}
                  </div>
                </div>

                <div className="col-lg-9 col-md-8">
                  {/* List content start */}
                  <div className="list-holder">
                    <ol>
                    {data?.blogpoints?.map((val) => (
                      <li id={val?.id}>
                        <div className="list-text-holder">
                          <h3>{val?.point}</h3>
                          {parse(val?.description || "")}
                          {/* <p className="main-list-text">
                            There are several types of auto insurance, each offering different levels of protection. Here’s a breakdown of the most common options:
                          </p>
                          <p className="side-padding">
                            Liability Insurance: This is the most basic coverage and is usually required by law. It covers the costs of damage or injuries you cause to others in an accident.
                          </p>
                          <p className="side-padding">
                            Collision Insurance: This type of coverage pays for damage to your vehicle resulting from a collision, regardless of who is at fault.
                          </p>
                          <p className="side-padding">
                            Comprehensive Insurance: This protects against non-collision-related damages, such as theft, fire, vandalism, or natural disasters.
                          </p>
                          <p className="side-padding">
                            Personal Injury Protection (PIP): PIP covers medical expenses for you and your passengers, regardless of fault, and may include lost wages or funeral costs.
                          </p>
                          <p className="side-padding">
                            Uninsured/Underinsured Motorist Coverage: This helps cover expenses if you’re involved in an accident with a driver who has little or no insurance.
                          </p> */}
                        </div>
                      </li>))}

                      {/* <li id="legal_requirement">
                        <div className="list-text-holder">
                          <h3>Auto insurance is not just a legal requirement:</h3>
                          <p className="main-list-text">
                            It’s a smart financial decision. Without proper coverage, you could face significant out-of-pocket expenses if you're involved in an accident. Here are a few reasons why auto insurance is essential:
                          </p>
                          <p className="side-padding">
                            Legal Requirement: Most states require a minimum level of liability insurance to operate a vehicle legally.
                          </p>
                          <p className="side-padding">
                            Financial Protection: Repairs or medical bills after an accident can be expensive. Insurance helps cover these costs, so you’re not left paying the full amount.
                          </p>
                          <p className="side-padding">
                            Peace of Mind: Knowing that you’re protected against the unexpected allows you to drive with confidence.
                          </p>
                        </div>
                      </li>

                      <li id="Factors">
                        <div className="list-text-holder">
                          <h3>Factors That Affect Your Auto Insurance Premium:</h3>
                          <p className="main-list-text">
                            Several factors determine the cost of your auto insurance premium. Understanding these factors can help you find ways to lower your rates:
                          </p>
                          <p className="side-padding">
                            Driving Record: Drivers with a clean driving history typically pay lower premiums, while those with accidents or violations may pay more.
                          </p>
                          <p className="side-padding">
                            Vehicle Type: The make, model, and year of your vehicle affect your premium. More expensive or high-performance cars may cost more to insure.
                          </p>
                          <p className="side-padding">
                            Location: Where you live impacts your premium. Areas with higher rates of accidents, theft, or natural disasters may have higher insurance costs.
                          </p>
                          <p className="side-padding">
                            Credit Score: In some regions, insurers use your credit score to determine rates, with better credit leading to lower premiums.
                          </p>
                          <p className="side-padding">
                            Coverage Level: Choosing higher coverage limits or adding optional coverages, like comprehensive or collision, can increase your premium.
                          </p>
                        </div>
                      </li> */}
                    </ol>
                  </div>
                  {/* List content end */}

                  {/* Conclusion text start */}
                  <div className="conclusion-text">
                    <h3>Conclusion</h3>
                    <p>
                    {parse(data?.conclusion || "")}
                    </p>
                  </div>
                  {/* Conclusion text end */}

                  {/* Comment card start */}
                  <div className="Comments-sec-holder">
                    <div className="Comments_heading-holder">
                      <h3>Comments</h3>
                    </div>
                    <div className="comment_box_holder">
                      <Comments_card_holder data={data?.blog_comments}/>
                    </div>
                    {/* <div className="comment_box_holder">
                      <Comments_card_holder />
                    </div>
                    <div className="comment_box_holder">
                      <Comments_card_holder />
                    </div> */}
                  </div>
                  {/* Comment card end */}

                  {/* Leave Comment form start */}
                  <div className="Leave_Comment_section">
                    <Leave_Comment blogData={data} getAllData={getAllData}/>
                  </div>
                  {/* Leave Comment form end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog_inner_content;
