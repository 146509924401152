import React, { useState, useEffect, useContext } from "react";
import Blog_inner_Banner from "../../Common_component/Blog_inner_Banner/Blog_inner_Banner";
import Blog_inner_content from "./Blog_inner_content/Blog_inner_content";
import Leave_Comment from "./Leave_Comment/Leave_Comment";
import Breadcrumbs_Holder from "../../Common_component/Breadcrumbs_Holder/Breadcrumbs_Holder";
import { Context } from "../../../utils/context";
import { getAllProudtoPartner, getAllDataHappyCustomerData, getBlogs, getInnerBlogs } from "../../../utils/apis/common/Common";
import { useParams } from 'react-router-dom';
function Blog_inner() {
  const [data, setData] = useState();
  const breadcrumbs = [
    { label: "Blogs", link: "/blogs", active: false },
    {
      label: data?.title,
      link: "",
      active: true,
    },
  ];

 
  const { IMG_URL } = useContext(Context);
  console.log(IMG_URL, "IMG_URL IMG_URL");

  const { id } = useParams();
  const getAllData = async () => {
    const res = await getInnerBlogs(id);

    if (res?.success) {
      setData(res?.data?.list);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }
  useEffect(() => {
    getAllData();

  }, [id]);
  return (
    <>
      <Breadcrumbs_Holder breadcrumbs={breadcrumbs} data={data} />

      <Blog_inner_Banner data={data} IMG_URL={IMG_URL} />

      <Blog_inner_content data={data} getAllData={getAllData}/>
    </>
  );
}

export default Blog_inner;
