import React from "react";
import How_to_Buy_card from "../../../Common_component/How_to_Buy_card/How_to_Buy_card";

function Health_Insurance_How_to_Buy({ howToBuyPlanHeading }) {
  return (
    <>
      <section className="How_to_Buy">
        <div className="container">
          <div className="How_to_Buy-holder">
            <div className="section-heading-holder">
              <h3>{howToBuyPlanHeading}</h3>
            </div>

            <div className="How_to_Buy_card_holder">
              <div className="card_holder">
                <How_to_Buy_card
                  cardNumber={"1"}
                  cardHeading={"Contact Us:"}
                  content={
                    <>
                      <p>Reach out to us to explore different health insurance
                      options. Whether you're looking for{" "}
                      <b>individual health insurance, family health coverage</b>
                      , or <b>senior citizen plans</b>, we can guide you to the
                      right one.</p>
                    </>
                  }
                />
              </div>

              <div className="card_holder">
                <How_to_Buy_card
                  cardNumber={"2"}
                  cardHeading={"Share Your Needs: "}
                  content={
                    "Provide us with details about your health, any existing medical conditions, and previous health insurance policies, if applicable. Discuss your specific coverage requirements with our insurance experts, and let them recommend the most suitable options for you and your family, based on your health needs and budget."
                  }
                />
              </div>

              <div className="card_holder">
                <How_to_Buy_card
                  cardNumber={"3"}
                  cardHeading={"Get a Quote:"}
                  content={
                    "We will prepare a personalized health insurance quote that fits your coverage needs, including benefits like hospitalization, maternity coverage, pre-existing conditions, and more. You can also choose additional riders such as critical illness cover or day-care procedures for a more comprehensive plan."
                  }
                />
              </div>

              <div className="card_holder">
                <How_to_Buy_card
                  cardNumber={"4"}
                  cardHeading={"Choose and Enjoy Coverage:"}
                  content={
                    "Carefully review the health insurance policy details, including coverage limits, exclusions, and network hospitals. Once you're satisfied, complete the application process and start enjoying the peace of mind that comes with full health coverage!"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Health_Insurance_How_to_Buy;
