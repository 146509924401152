import React from "react";
import "./Home_Insurance_About_us.css"

function Home_Insurance_About_us({ aboutHeading }) {
  return (
    <>
      <section className="Four_Wheeler_About_Us Home_Insurance_About_us">
        <div className="container">
          <div className="Four_Wheeler_About_Us_holder">
            <div className="section-heading-holder">
              <h3>{aboutHeading}</h3>
            </div>

            <div className="content-holder">
              <p>
                Health insurance policy is a product that protects you against
                the financial implications of a wide variety of health-related
                expenses, ranging from those caused by minor illnesses and
                injuries to critical diseases. Therefore, health insurance plans
                and the cumulative Bonus serve as a protective financial shield
                for you should you be faced with a major medical expense.
              </p>
              <p>
                Selecting a suitable medical insurance is difficult yet
                incredibly important for you to have a sufficient degree of
                preparedness against any sudden and, in some cases, expected
                medical expenses. Imagine a scenario wherein you are diagnosed
                with a serious medical condition that requires consistent
                treatment, hospitalisation, and/or surgery. Or simply take the
                example of the spread of COVID-19 in India and imagine the
                various expenses you may have to look after if diagnosed with a
                COVID-19 infection
              </p>{" "}
              <p>
                In such a situation, you would not want to let your medical
                bills dig a massive hole in your savings or be faced with a
                financial crisis to pay said bills, will you? Hence it is
                pivotal to buy a suitable health insurance plan, choose the
                right sum insured, pay the applicable premium and get insured at
                the earliest, and we can help you with that.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home_Insurance_About_us;
