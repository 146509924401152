import React from "react";
import How_to_Buy from "../Four_Wheeler_Insurance/How_to_Buy/How_to_Buy";
import Two_Wheeler_Insurance_Banner from "./Two_Wheeler_Insurance_Banner/Two_Wheeler_Insurance_Banner";
import Two_Wheeler_About_Us from "./Two_Wheeler_About_Us/Two_Wheeler_About_Us";
import Types_of_Two_Wheeler from "./Types_of_Two_Wheeler/Types_of_Two_Wheeler";
import Two_Wheeler_Third_party_Insurance from "./Two_Wheeler_Third_party_Insurance/Two_Wheeler_Third_party_Insurance";
import Two_Wheeler_How_to_Buy from "./Two_Wheeler_How_to_Buy/Two_Wheeler_How_to_Buy";

function Two_Wheeler_Insurance() {
  return (
    <>
      <Two_Wheeler_Insurance_Banner
        imgSrc={
          "/assets/image/Services/Wheeler_Service_Banner/Four-Wheeler-Service-Banner-img-1.png"
        }
        formText={"2 Wheeler Insurance"}
        bannerText={"2 Wheeler Insurance"}
      />

      <Two_Wheeler_About_Us aboutHeading={"About 2 Wheeler Insurance"} />

      <Types_of_Two_Wheeler typeHeading={"Type of Two-Wheeler Insurance"}/>

      <Two_Wheeler_Third_party_Insurance />

      <Two_Wheeler_How_to_Buy howToBuyPlanHeading={"How to Buy a Two-Wheeler Insurance Plan"}/>
    </>
  );
}

export default Two_Wheeler_Insurance;
