import React from "react";
import "./Other_Service_banner.css";

function Other_Service_banner({bannerText}) {
  return (
    <>
      <section className="Other_Service_banner">
        <div className="banner-heading-holder">
          <h4>{bannerText}</h4>
        </div>
      </section>
    </>
  );
}

export default Other_Service_banner;
