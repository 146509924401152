import React from "react";

function Two_Wheeler_About_Us({ aboutHeading }) {
  return (
    <>
      <section className="Four_Wheeler_About_Us">
        <div className="container">
          <div className="Four_Wheeler_About_Us_holder">
            <div className="section-heading-holder">
              <h3>{aboutHeading}</h3>
            </div>

            <div className="content-holder">
              <div className="text-holder">
                <h3>Start Your Two-Wheeler Insurance Journey</h3>
                <p>
                  Two-wheeler insurance is a vital agreement between you and
                  your insurance provider that safeguards your motorcycle or
                  scooter against financial losses due to unexpected events such
                  as accidents, theft, or natural disasters. Many see this
                  insurance as just a document, but it provides essential
                  financial security. Accidents can happen to anyone, and repair
                  costs can quickly add up, making coverage indispensable.
                </p>
              </div>

              <div className="text-holder">
                <h3>Types of Two-Wheeler Insurance</h3>
                <p>
                  There are different types of two-wheeler insurance available:
                </p>

                <ul>
                  <li>
                    <p>
                      <b>Third-Party Liability Insurance:</b> This mandatory
                      coverage protects you against financial liabilities if
                      your bike causes damage to another person’s vehicle or
                      injures someone. It is legally required under the Motor
                      Vehicle Act in India.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Comprehensive Two-Wheeler Insurance:</b> This policy
                      offers broader coverage, protecting you from both
                      third-party liabilities and damages to your own bike,
                      whether from accidents, theft, fire, or natural disasters.
                      It also covers man-made damages like vandalism.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Personal Accident Cover:</b> This optional add-on
                      provides financial assistance for medical expenses or
                      compensation in case of injury or death resulting from an
                      accident.
                    </p>
                  </li>
                </ul>
              </div>

              <div className="text-holder">
                <h3>Buying and Customizing Your Insurance</h3>
                <p>
                  When purchasing two-wheeler insurance online, you can choose
                  between third-party coverage, comprehensive coverage, or
                  add-ons based on your individual needs. Tata AIG offers
                  various policies to help you find the right coverage for your
                  two-wheeler. Simply enter your bike details and receive a
                  personalized quote to select the ideal insurance plan.
                </p>
              </div>

              <div className="text-holder">
                <h3>Importance of Renewal</h3>
                <p>
                  Timely renewal of your two-wheeler insurance is crucial to
                  maintain uninterrupted coverage. As third-party insurance
                  rates are subject to revisions, staying informed and ensuring
                  your policy is current will provide peace of mind while
                  riding.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Two_Wheeler_About_Us;
