import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import Common_btn_holder from "../../../../Common_component/Common_btn/Common_btn_holder";
import Bottom_Successfull_Modal from "../../../../Common_component/Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { postInsurance } from "../../../../../utils/apis/common/Common";
const SixStep = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const options = [
    { value: "5 lakh", label: "5 lakh" },
    { value: "10 Lakh", label: "10 Lakh" },
    { value: "15 lakh", label: " 15 lakh" },
    { value: "20 Lakh", label: "20 Lakh" },
    { value: "50 Lakh", label: " 50 Lakh" },
    { value: "1 crore", label: "1 crore" },
    { value: "2 crore", label: "2 crore" },
    { value: "3 crore", label: "3 crore" },
    
  ];


  const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();

  const onSubmit = async (data) => {
    console.log(data, "data data");
    const formattedData = {

      first_name: data.first_name,
      sum_insured: data.sum_insured.value,
      last_name: data.last_name,
      email: data.email,
      city: data.city,
      contact_number: data.contact_number,
      type: 'Health',

    };
    const res = await postInsurance(formattedData)
    if (res?.success) {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        reset();
      }, 3000);

    } else {
      // if (res?.message?.email) {
      //   setError("email", { message: res?.message?.email });
      // }
      // if (res?.message?.contact_no) {
      //   setError("contact_no", { message: res?.message?.contact_no });
      // }
    }
  }
  return (
    <>
      <section className="main-form">
        <Form className="form-holder">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
              <Form.Group className="" controlId="exampleForm.ControlInput1">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter First Name"
                  {...register("first_name", {
                    required: "First Name is required",
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.first_name,
                  })}
                  onKeyDown={(event) => {
                    if (!/[A-Z-a-z ]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                {errors.first_name && <div className="invalid-feedback">{errors.first_name.message}</div>}
              </Form.Group>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
              <Form.Group className="" controlId="exampleForm.ControlInput1">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter Last Name"
                  {...register("last_name", {
                    required: "Last Name is required",
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.last_name,
                  })}
                  onKeyDown={(event) => {
                    if (!/[A-Z-a-z ]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                {errors.last_name && <div className="invalid-feedback">{errors.last_name.message}</div>}
              </Form.Group>
            </div>


            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
              <Form.Group className="" controlId="exampleForm.ControlInput1">
                <Form.Control
                  className={classNames("", {
                    "is-invalid": errors?.contact_number,
                    "is-valid": getValues("contact_number"),
                  })}
                  type="text"
                  name="contact_number"
                  placeholder="Enter Mobile Number"
                  {...register("contact_number", {
                    required: "Contact No is required",
                    minLength: {
                      value: 10,
                      message:
                        "Number should be at least 10 characters",
                    },
                  })}
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      if (event.key !== "Backspace") {
                        event.preventDefault();
                      }
                    }
                  }}
                  maxLength={10}
                />
                {errors?.contact_number && (
                  <sup className="text-danger">
                    {errors?.contact_number?.message}
                  </sup>
                )}
              </Form.Group>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-field-holder">
              <Form.Group className="" controlId="exampleForm.ControlInput1">
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="E-mail Address"
                  {...register("email", {
                    required: "Email Id required",
                    pattern: {
                      value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
                      message: "Email Id Invalid",
                    },
                  })}
                  className={classNames("", { "is-invalid": errors?.email })}
                />
                {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
              </Form.Group>
            </div>


            <div className="col-lg-6 col-md-6 col-sm-6 col-6 form-field-holder">
              {/* <Select classNamePrefix={"selectHolder"} placeholder="City" options={options} /> */}
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter City"
                {...register("city", {
                  required: "city is required",
                })}
                className={classNames("", {
                  "is-invalid": errors?.city,
                })}

              />
              {errors.city && <div className="invalid-feedback">{errors.city.message}</div>}
            </div>


            <div className="col-lg-6 col-md-6 col-sm-6 col-6 form-field-holder">
              {/* <Select classNamePrefix={"selectHolder"} placeholder="Sum Insured" options={options} /> */}
              <Controller
                name="sum_insured" // Field name in form data
                control={control}
                rules={{ required: "Sum insured is required" }}
                defaultValue={null} // Set initial value
                render={({ field }) => (
                  <Select
                    {...field} // spread the field props
                    options={options}
                    placeholder="Sum Insured"
                    classNamePrefix="selectHolder"
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption); // Update form state with selected option
                    }}
                  />
                )}
              />
              {errors?.sum_insured && (
                <sup className="text-danger">{errors.sum_insured.message}</sup>
              )}
            </div>
          </div>
        </Form>

        <Common_btn_holder
          onClick={handleSubmit(onSubmit)}
          // onClick={() => setModalShow(true)}
          btnText={"Get Quote"}
        />
      </section>

      <Bottom_Successfull_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        text={"Quote Generated Successfully!"}
      />
    </>
  );
};

export default SixStep;
