import React from "react";
import "./Third_party_Insurance.css";
import { Table } from "react-bootstrap";

function Third_party_Insurance() {
  return (
    <section className="Third_party_Insurance">
      <div className="container">
        <div className="Third_party_Insurance_holder">
          <div className="section-heading-holder">
            <h3>Comparison of Car Insurance Types</h3>
          </div>

          <div className="Third_party_Insurance_content">
            <p>
              <b>Bumper to Bumper vs Comprehensive Car Insurance</b>
            </p>
            <p>
              Here’s a comparison between bumper to bumper and comprehensive car
              insurance:
            </p>
          </div>

          <div className="Third_party_Insurance_table_container ">
            <div className="table-responsive around-space-holder">
              <Table className="comparison-table">
                <thead>
                  <tr>
                    <th className="border-right-holder">Sr. No</th>
                    <th className="border-right-holder">Feature</th>
                    <th className="border-right-holder">
                      Bumper to Bumper Insurance
                    </th>
                    <th className="border-right-holder">
                      Comprehensive Insurance
                    </th>
                    <th className="border-none-holder">
                      Third-Party Liability Insurance
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-bottom-holder">
                    <td className="que-text border-right-holder">01</td>
                    <td className="que-text border-right-holder">
                      Coverage Type
                    </td>
                    <td className="border-right-holder">
                      Covers own car damages and third-party damages.
                    </td>
                    <td className="border-right-holder">
                      Covers damage to own car and third-party damages.
                    </td>
                    <td className="border-none-holder">
                      Covers only third-party damages to others’ vehicles or
                      property.
                    </td>
                  </tr>
                  <tr className="border-bottom-holder">
                    <td className="que-text border-right-holder">02</td>
                    <td className="que-text border-right-holder">Own Damage</td>
                    <td className="border-right-holder">
                      Includes coverage for damages to your own vehicle.
                    </td>
                    <td className="border-right-holder">
                      Includes coverage for damages to your own vehicle.
                    </td>
                    <td className="border-none-holder">
                      Does not cover any damages to your own vehicle.
                    </td>
                  </tr>
                  <tr className="border-bottom-holder">
                    <td className="que-text border-right-holder">03</td>
                    <td className="que-text border-right-holder">
                      Third-Party Coverage
                    </td>
                    <td className="border-right-holder">
                      Includes third-party liability coverage.
                    </td>
                    <td className="border-right-holder">
                      Includes third-party liability coverage.
                    </td>
                    <td className="border-none-holder">
                      Provides coverage for third-party damages only.
                    </td>
                  </tr>
                  <tr className="border-bottom-holder">
                    <td className="que-text border-right-holder">04</td>
                    <td className="que-text border-right-holder">
                      Natural Disasters
                    </td>
                    <td className="border-right-holder">
                      Covers damages from natural disasters.
                    </td>
                    <td className="border-right-holder">
                      Covers damages from natural disasters.
                    </td>
                    <td className="border-none-holder">
                      Does not cover damages from natural disasters or theft.
                    </td>
                  </tr>
                  <tr className="border-bottom-holder">
                    <td className="que-text border-right-holder">05</td>
                    <td className="que-text border-right-holder">
                      Premium Cost
                    </td>
                    <td className="border-right-holder">
                      Generally higher due to extensive coverage.
                    </td>
                    <td className="border-right-holder">
                      Generally higher due to extensive coverage.
                    </td>
                    <td className="border-none-holder">
                      Usually lower, making it a more budget-friendly option.
                    </td>
                  </tr>
                  <tr className="border-bottom-holder">
                    <td className="que-text border-right-holder">06</td>
                    <td className="que-text border-right-holder">Add-Ons</td>
                    <td className="border-right-holder">
                      Can include additional protections.
                    </td>
                    <td className="border-right-holder">
                      Can include additional protections.
                    </td>
                    <td className="border-none-holder">
                      Limited or no options for add-ons.
                    </td>
                  </tr>
                  <tr>
                    <td className="que-text border-right-holder">07</td>
                    <td className="que-text border-right-holder">
                      Legal Requirement
                    </td>
                    <td className="border-right-holder">
                      Not legally required; however, it is recommended.
                    </td>
                    <td className="border-right-holder">
                      Not legally required; however, it is recommended.
                    </td>
                    <td className="border-none-holder">
                      Legally required in most countries to drive on the road.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="Third_party_Insurance_holder ">
          <div className="Third_party_Insurance_content">
            <p>
              <b>Comprehensive vs Third-Party Liability Insurance</b>
            </p>
            <p>
              Here’s a comparison between comprehensive insurance and
              third-party liability insurance:
            </p>
          </div>

          <div className="Third_party_Insurance_table_container ">
            <div className="table-responsive around-space-holder">
              <Table className="comparison-table">
                <thead>
                  <tr>
                    <th className="border-right-holder">Sr. No</th>
                    <th className="border-right-holder">Feature</th>
                    <th className="border-right-holder">
                      Comprehensive Insurance
                    </th>
                    <th className="border-right-holder">
                      Third-Party Liability Insurance
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-bottom-holder">
                    <td className="que-text border-right-holder">01</td>
                    <td className="que-text border-right-holder">
                      Coverage Type
                    </td>
                    <td className="border-right-holder">
                      Covers damage to your own car and third-party damages.
                    </td>
                    <td className="border-none-holder">
                      Covers only third-party damages to others’ vehicles or
                      property.
                    </td>
                  </tr>
                  <tr className="border-bottom-holder">
                    <td className="que-text border-right-holder">02</td>
                    <td className="que-text border-right-holder">Own Damage</td>
                    <td className="border-right-holder">
                      Includes coverage for damages to your own vehicle.
                    </td>
                    <td className="border-none-holder">
                      Does not cover any damages to your own vehicle.
                    </td>
                  </tr>
                  <tr className="border-bottom-holder">
                    <td className="que-text border-right-holder">03</td>
                    <td className="que-text border-right-holder">
                      Third-Party Coverage
                    </td>
                    <td className="border-right-holder">
                      Includes third-party liability coverage.
                    </td>
                    <td className="border-none-holder">
                      Provides coverage for third-party damages only.
                    </td>
                  </tr>
                  <tr className="border-bottom-holder">
                    <td className="que-text border-right-holder">04</td>
                    <td className="que-text border-right-holder">
                      Natural Disasters
                    </td>
                    <td className="border-right-holder">
                      Covers damages from natural disasters, theft, and
                      vandalism.
                    </td>
                    <td className="border-none-holder">
                      Does not cover damages from natural disasters or theft.
                    </td>
                  </tr>
                  <tr className="border-bottom-holder">
                    <td className="que-text border-right-holder">05</td>
                    <td className="que-text border-right-holder">
                      Premium Cost
                    </td>
                    <td className="border-right-holder">
                      Generally higher due to extensive coverage.
                    </td>
                    <td className="border-none-holder">
                      Usually lower, making it a more budget-friendly option.
                    </td>
                  </tr>
                  <tr className="border-bottom-holder">
                    <td className="que-text border-right-holder">06</td>
                    <td className="que-text border-right-holder">Add-Ons</td>
                    <td className="border-right-holder">
                      Can be enhanced with add-ons for additional protection.
                    </td>
                    <td className="border-none-holder">
                      Limited or no options for add-ons.
                    </td>
                  </tr>
                  <tr>
                    <td className="que-text border-right-holder">07</td>
                    <td className="que-text border-right-holder">
                      Legal Requirement
                    </td>
                    <td className="border-right-holder">
                      Not legally required; however, it is recommended for
                      better protection.
                    </td>
                    <td className="border-none-holder">
                      Legally required in most countries to drive on the road.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Third_party_Insurance;
