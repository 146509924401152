import React from 'react';
import './Breadcrumbs_Holder.css';

function Breadcrumbs_Holder({ breadcrumbs,data }) {
  return (
    <div className='Breadcrumbs_Holder'>
      <div className='container'>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {breadcrumbs.map((crumb, index) => (
              <li
                key={index}
                className={`breadcrumb-item ${crumb.active ? 'active' : ''}`}
                aria-current={crumb.active ? 'page' : undefined}
              >
                {!crumb.active ? <a href={crumb.link}>{crumb.label}</a> : crumb.label}
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
}

export default Breadcrumbs_Holder;
