import React from "react";

function Why_Need_Travel_Insurance() {
  return (
    <>
      <section className="Types_of_Four_Wheeler Home_Insurance_Need_Health_Insurance">
        <div className="container">
          <div className="Types_of_Four_Wheeler_holder">
            <div className="heading-holder">
              <h3>Why travel insurance is important??</h3>
            </div>
            <div className="Types_of_Four_Wheeler_content">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <div className="content-list-holder">
                    <ol>
                      <li>
                        <div className="text-holder">
                          <h3>Medical Emergencies Abroad</h3>
                          <p>
                            Medical expenses in foreign countries can be
                            exorbitant, especially in regions like the U.S. or
                            Europe. Travel insurance provides coverage for
                            emergency medical treatments, hospital stays, and
                            even repatriation if needed, ensuring you don’t bear
                            the high costs out-of-pocket. Some policies may also
                            cover pre-existing conditions with additional
                            premiums​.
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="text-holder">
                          <h3> Trip Cancellation or Interruption</h3>
                          <p>
                            Unforeseen events such as illness, injury, or even
                            natural disasters can lead to last-minute trip
                            cancellations or interruptions. Travel insurance
                            helps recoup non-refundable expenses, such as hotel
                            bookings or flight tickets, providing peace of mind
                            that your investment in travel is protected.
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="text-holder">
                          <h3>Loss of Luggage and Personal Belongings</h3>{" "}
                          <p>
                            Losing luggage or valuables during travel is common
                            and inconvenient. Travel insurance covers the cost
                            of lost, stolen, or damaged baggage, including items
                            like passports and electronics. This coverage helps
                            travellers manage unexpected expenses and minimizes
                            the disruption caused by such losses.
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="text-holder">
                          <h3>Emergency Evacuation and Repatriation </h3>{" "}
                          <p>
                            In case of natural disasters, political unrest, or
                            medical emergencies that require evacuation,
                            international travel insurance covers the cost of
                            transporting you to a safer location or your home
                            country. These services can be incredibly expensive
                            if arranged privately, making insurance an
                            invaluable backup.
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="text-holder">
                          <h3>24/7 Assistance and Support Services</h3>{" "}
                          <p>
                            Many international travel insurance providers offer
                            24/7 assistance, helping with services like finding
                            local medical providers, language interpretation, or
                            legal support in case of emergencies. This support
                            can be crucial when traveling in unfamiliar
                            territories or dealing with local legal systems.
                          </p>
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Why_Need_Travel_Insurance;
